// 数字市场api
import axios from '../request/request.js'
//
export default {
  // 模特列表参数
  modelParams(data) {
    return axios({
      url: "/member/market/model/params",
      method: "get",
      data,
    });
  },
  // 模特列表
  modelList(data) {
    return axios({
      url: "/member/market/model",
      method: "get",
      data,
    });
  },
  // 模特详情
  modelDetails(id, data) {
    return axios({
      url: "/member/market/model/" + id,
      method: "get",
      data,
    });
  },
  // 模特分身参数
  modelScenceParams(data) {
    return axios({
      url: "/member/market_model_scence/params",
      method: "get",
      data,
    });
  },
  // 获取模特分身
  modelScenceList(data) {
    return axios({
      url: "/member/market_model_scence/index",
      method: "get",
      data,
    });
  },
  // 购买模特生成订单
  modelOrder(data) {
    return axios({
      url: "/member/market_model/add_order",
      method: "post",
      data,
    });
  },
  // 购买模特
  modelBuy(data) {
    return axios({
      url: "/member/market_model/buy",
      method: "post",
      data,
    });
  },
  // 声音标签
  ttsParams(data) {
    return axios({
      url: "/member/tts_label/select",
      method: "get",
      data,
    });
  },
  // 声音列表
  ttsList(data) {
    return axios({
      url: "/member/market_tts",
      method: "get",
      data,
    });
  },
  // 绘画参数
  drawParams(data) {
    return axios({
      url: "/member/market_draw/params",
      method: "get",
      data,
    });
  },
  // 绘画列表
  drawList(data) {
    return axios({
      url: "/member/market_draw",
      method: "get",
      data,
    });
  },
  // 绘画详情
  drawDetails(id) {
    return axios({
      url: "/member/market_draw/" + id,
      method: "get",
    });
  },
  // 模板市场参数
  templateParams(data) {
    return axios({
      url: "/member/market_template/params",
      method: "get",
      data
    });
  },
  // 模板市场获取主题分类
  themeType(data) {
    return axios({
      url: "/member/market_template/theme_type",
      method: "get",
      data
    });
  },
  // 模板市场列表
  templateList(data) {
    return axios({
      url: "/member/market_template/video_index",
      method: "get",
      data
    });
  },
  // 购买模板生成订单
  templateOrder(data) {
    return axios({
      url: "/member/market_template/add_order",
      method: "post",
      data,
    });
  },
  // 购买模板
  templateBuy(data) {
    return axios({
      url: "/member/market_template/buy",
      method: "post",
      data,
    });
  },
  // 名片市场参数
  cardParams(data) {
    return axios({
      url: "/member/market_card/params",
      method: "get",
      data
    });
  },
  // 名片市场列表
  cardList(data) {
    return axios({
      url: "/member/market_card/get_list",
      method: "get",
      data
    });
  },
  // 名片市场  使用 获取参数
  getTtsParams(data) {
    return axios({
      url: "/member/tts_create/params",
      method: "get",
      data
    });
  },
  // 名片市场  使用 声音列表
  getTtsList(data) {
    return axios({
      url: "/member/tts_list/select",
      method: "get",
      data
    });
  },
  //声音试听
  postAudition(data) {
    return axios({
      url: "/member/tts_create/audition",
      method: "post",
      data,
      responseType: 'blob'
    });
  },
  // 创建名片合成id
  creatCard(data) {
    return axios({
      url: "/member/video",
      method: "post",
      data
    });
  },
  // 查看名片创建进度
  creatCardInfo(id) {
    return axios({
      url: "/member/video/" + id,
      method: "get",
    });
  },
  // 编辑名片
  putCard(data, id) {
    return axios({
      url: "/member/video/" + id,
      method: "put",
      data
    });
  },
  // 保存名片模板
  postTemplate(data) {
    return axios({
      url: "/member/video/up_template",
      method: "post",
      data
    });
  },
  // 保存名片模板
  copyVideo(data) {
    return axios({
      url: "/member/video/copy_video",
      method: "get",
      data
    });
  },
}
