<!-- @Author: Yu_Bo -->
<template>
    <div>
        <div>
            <el-dialog class='pay_page zc_dialog_box' :visible.sync="dialogVisible" width="720px" :close-on-click-modal='false' :append-to-body="true" :before-close='beforeCloseBtn'>
                <div class="title" slot="title">
                    <div class="name">支付</div>
                </div>
                <div class="pay_content" v-loading='loading'>
                    <div class="left">
                        <div class="title">订单号：</div>
                        <div class="text">
                            <span class="t_txt">{{order.order_num}}</span>
                            <span class="t_tip" @click="copyBtn">复制</span>
                        </div>
                        <div class="title title_two">订单详情：</div>
                        <div class="text text_two" v-if="status==1">
                            <span class="t_txt">{{order.product_category}}: {{order.product_name}}</span>
                            <span class="t_txt">有效期: {{order.valid}}</span>
                        </div>
                        <div class="text text_two" v-if="status==2">
                            <span class="t_txt">会员VIP专属数字人</span>
                            <span class="t_txt">会员VIP专属声音</span>
                            <span class="t_txt" v-for="(ite,key) in vipInfo.rights" :key="key">{{ite.unit_text}} {{ite.product_name}} <span style="color: #E00C25;">（{{ite.valid_text}}）</span></span>
                        </div>
                        <div class="text text_two" v-if="status==3">
                            <span class="t_txt">模特名称: {{order.name}}</span>
                        </div>
                        <div class="text text_two" v-if="status==4">
                            <span class="t_txt">雇佣模特： {{order.name}}</span>
                            <span class="t_txt">应用场景： {{order.label}}</span>
                            <span class="t_txt">有效期： {{order.expire_day}}天</span>
                            <span class="t_txt">视频时长： {{order.give_minute}}分钟</span>
                        </div>
                        <div class="text text_two" v-if="status==5">
                            <span class="t_txt">声音克隆： {{order.name}}</span>
                        </div>
                        <div class="text text_two" v-if="status==6">
                            <span class="t_txt">模板类型： 付费模板</span>
                            <span class="t_txt">模板名称： {{order.name}}</span>
                        </div>
                        <div class="text text_two" v-if="status==7">
                            <span class="t_txt">台本名称名称： {{order.name}}</span>
                        </div>
                    </div>
                    <div class="right">
                        <div class="title">请选择付款方式：</div>
                        <div class="right_pay">
                            <div class="pay_nav">
                                <span :class="pay_index==1?'nav_active':''" @click="pay_index=1">钻石支付</span>
                                <span :class="pay_index==2?'nav_active':''" @click="pay_index=2">兑换码支付</span>
                            </div>
                            <div class="pay_info" v-if='pay_index==1'>
                                <div class="info_tip">订单总额</div>
                                <div class="info_price">
                                    <img src="@/assets/images/icon/diamond_icon.png" alt="">
                                    <span>{{status==1?order.total_amount:order.diamond}}</span>
                                </div>
                                <div class="info_yh" v-if="status==2 && order.youhui">
                                    <span class="txt">优惠额度</span>
                                    <img src="@/assets/images/icon/diamond_icon.png" alt="">
                                    <span class="num">{{order.youhui}}</span>
                                </div>
                                <div class="info_btn" v-if="status==1">
                                    <el-button class="btnBgColor_blue" :loading='btnLoading' type="primary" @click="payAffirmBtn(1)">{{order.total_amount>order.diamond?'余额不足 立即充值':'立即支付'}}</el-button>
                                </div>
                                <div class="info_btn" v-if="status==2||status==3||status==4||status==5||status==6||status==7">
                                    <el-button class="btnBgColor_blue" :loading='btnLoading' type="primary" @click="payAffirmBtn(1)">{{order.diamond>order.diamond_num?'余额不足 立即充值':'立即支付'}}</el-button>
                                </div>
                                <div class="info_bot">
                                    <div class='bot_left'>
                                        <span class="txt">当前钻石余额</span>
                                        <img src="@/assets/images/icon/diamond_icon.png" alt="">
                                        <span class="num">{{status==1?order.diamond:order.diamond_num}}</span>
                                    </div>
                                    <div class='bot_right' @click="payAffirmBtn(2)">
                                        <span class="txt">前往充值</span>
                                        <i class="el-icon-d-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="pay_input" v-if='pay_index==2'>
                                <div class="input">
                                    <el-input v-model="exchange_code" placeholder="请输入兑换码"></el-input>
                                </div>
                                <el-button class="btnBgColor_blue" :loading='codeLoading' size="small" type="primary" @click="affirmBtn">立即兑换</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </div>
        <!-- 提示 -->
        <point-out ref='dialogTip' @close='closeBtn' type='pay' dialogTitle="提示" dialogWidth='380px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">请确认是否放弃支付？</div>
            </div>
        </point-out>
        <!-- 账户余额 -->
        <account-balance ref='dialogAcc' @renovate='renovateBtn'></account-balance>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//温馨提示弹框
import AccountBalance from '@/components/account_balance/account_balance.vue'//账户余额
export default {
    components: {
        PointOut,
        AccountBalance
    },
    props: {},
    data() {
        return {
            dialogVisible: false,
            loading: false,
            status: 1,
            order: {
                diamond: '',
                id: '',
                order_num: '',
                product_category: '',
                product_name: '',
                total_amount: '',
                valid: '',
            },
            vipInfo: '',
            pay_index: 1,
            btnLoading: false,
            codeLoading: false,
            //
            exchange_code: '',
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(obj, status) {
            this.status = status
            if (status == 1) {
                // 充值
                this.orderInfo(obj)
            } else if (status == 2) {
                // 购买会员
                this.vipInfo = obj
                this.orderVipInfo(obj)
            } else if (status == 3) {
                // 购买训练次数
                this.order.order_num = obj.order_num
                this.order.name = obj.name
                this.order.diamond_num = obj.diamond
                this.order.diamond = obj.video_price
                this.order.training_video = obj.training_video
                this.order.grant_file = obj.grant_file
            } else if (status == 4) {
                // 购买模特
                this.order.order_num = obj.order_num
                this.order.name = obj.name
                this.order.diamond_num = obj.diamond
                this.order.diamond = obj.video_price
                this.order.label = obj.label.join('、 ')
                this.order.expire_day = obj.expire_day
                this.order.give_minute = obj.give_minute
                this.order.id = obj.id
            } else if (status == 5) {
                // 声音克隆
                this.order.order_num = obj.order_num
                this.order.name = obj.name
                this.order.diamond_num = obj.diamond
                this.order.diamond = obj.price
            } else if (status == 6) {
                // 购买模板
                this.order.order_num = obj.order_num
                this.order.name = obj.name
                this.order.diamond_num = obj.diamond
                this.order.diamond = obj.price
                this.order.id = obj.id
            } else if (status == 7) {
                // 购买台本
                this.order.order_num = obj.order_num
                this.order.name = obj.name
                this.order.diamond_num = obj.diamond
                this.order.diamond = obj.price
                this.order.id = obj.id
            }
            this.dialogVisible = true
        },
        trainNumber() {

        },
        // vip充值
        orderVipInfo(obj) {
            var that = this
            that.loading = true
            var params = {
                source: 1,
                vip_id: obj.id
            }
            that.$payApi.createConsumeOrder(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.order = res.result
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 获取订单信息
        orderInfo(obj) {
            var that = this
            that.loading = true
            var params = {
                product_category: obj.type,
                diamond_number: obj.diamond_number,
                earn_number: obj.earn_number,
                earn_unit: obj.earn_unit,
                order_source: 1,
                id: obj.id
            }
            that.$payApi.postOrder(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.order = res.result
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 复制订单号
        copyBtn() {
            this.$copyText(this.order.order_num).then((item) => {
                this.$succMsg('复制成功')
            })
        },
        // 切换
        navPayBtn(index) {
            this.pay_index = index
        },
        // 立即充值
        payAffirmBtn(type) {
            var that = this
            if (type == 1) {
                if (that.status == 1) {
                    if (that.order.total_amount > that.order.diamond) {
                        that.$refs.dialogAcc.openDialogBtn()
                        return
                    }
                }
                if (that.status == 2 || that.status == 3 || that.status == 4 || that.status == 5 || that.status == 6 || that.status == 7) {
                    if (that.order.diamond > that.order.diamond_num) {
                        that.$refs.dialogAcc.openDialogBtn()
                        return
                    }
                }
                // 支付
                that.btnLoading = true
                if (that.status == 1) {
                    var params = {
                        order_num: that.order.order_num,
                    }
                    that.$payApi.diamondPay(params).then(res => {
                        that.btnLoading = false
                        if (res.code == 1000) {
                            that.$succMsg('支付成功')
                            that.$store.dispatch('userInfo/getUserInfo')
                            that.closeBtn()
                            that.$emit('renovate')
                        } else {
                            that.$errMsg(res.message)
                        }
                    })
                } else if (that.status == 2) {
                    // 购买会员
                    var params = {
                        order_num: that.order.order_num,
                    }
                    that.$payApi.vipDiamondPay(params).then(res => {
                        that.btnLoading = false
                        if (res.code == 1000) {
                            that.$succMsg('支付成功')
                            that.$store.dispatch('userInfo/getUserInfo')
                            that.closeBtn()
                            that.$emit('renovate')
                        } else {
                            that.$errMsg(res.message)
                        }
                    })
                } else if (that.status == 3) {
                    var params = {
                        order_num: this.order.order_num,
                        name: this.order.name,
                        training_video: this.order.training_video,
                        grant_file: this.order.grant_file,
                        type: 1,
                    }
                    that.$workbenApi.payModel(params).then(res => {
                        that.btnLoading = false
                        if (res.code == 1000) {
                            that.$succMsg('支付成功')
                            that.$store.dispatch('userInfo/getUserInfo')
                            that.$emit('paySuccess')
                            that.closeBtn()
                        } else {
                            that.$errMsg(res.message)
                        }
                    })
                } else if (that.status == 4) {
                    var params = {
                        order_num: this.order.order_num,
                        type: 1,
                        id: this.order.id
                    }
                    that.$digitalApi.modelBuy(params).then(res => {
                        that.btnLoading = false
                        if (res.code == 1000) {
                            that.$succMsg('支付成功')
                            that.$store.dispatch('userInfo/getUserInfo')
                            that.$emit('renovate')
                            that.closeBtn()
                        } else {
                            that.$errMsg(res.message)
                        }
                    })
                } else if (that.status == 5) {
                    var params = {
                        order_num: this.order.order_num,
                    }
                    that.$workbenApi.audioDiamondPay(params).then(res => {
                        that.btnLoading = false
                        if (res.code == 1000) {
                            that.$succMsg('支付成功')
                            that.$store.dispatch('userInfo/getUserInfo')
                            that.$emit('paySuccess')
                            that.closeBtn()
                        } else {
                            that.$errMsg(res.message)
                        }
                    })
                } else if (that.status == 6) {
                    var params = {
                        order_num: this.order.order_num,
                        type: 1,
                        id: this.order.id
                    }
                    that.$digitalApi.templateBuy(params).then(res => {
                        that.btnLoading = false
                        if (res.code == 1000) {
                            that.$succMsg('支付成功')
                            that.$store.dispatch('userInfo/getUserInfo')
                            that.$emit('renovate')
                            that.closeBtn()
                        } else {
                            that.$errMsg(res.message)
                        }
                    })
                } else if (that.status == 7) {
                    var params = {
                        order_num: this.order.order_num,
                        type: 1,
                        id: this.order.id
                    }
                    that.$workbenApi.marketScriptBuy(params).then(res => {
                        that.btnLoading = false
                        if (res.code == 1000) {
                            that.$succMsg('支付成功')
                            that.$store.dispatch('userInfo/getUserInfo')
                            that.$emit('renovate')
                            that.closeBtn()
                        } else {
                            that.$errMsg(res.message)
                        }
                    })
                }
            } else {
                that.$refs.dialogAcc.openDialogBtn()
            }
        },
        // 立即兑换
        affirmBtn() {
            var that = this
            if (that.exchange_code == '') {
                that.$errMsg('请输入兑换码')
            } else if (that.status == 1) {
                that.codeLoading = true
                var params = {
                    order_num: that.order.order_num,
                    code: that.exchange_code
                }
                that.$payApi.codePay(params).then(res => {
                    that.codeLoading = false
                    if (res.code == 1000) {
                        that.$succMsg('支付成功')
                        that.closeBtn()
                        that.$emit('renovate')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.status == 2) {
                that.codeLoading = true
                var params = {
                    order_num: that.order.order_num,
                    code: that.exchange_code,
                    resource: 1,
                }
                that.$payApi.exchangeMemberCode(params).then(res => {
                    that.codeLoading = false
                    if (res.code == 1000) {
                        that.$succMsg('支付成功')
                        that.closeBtn()
                        that.$emit('renovate')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.status == 3) {
                that.codeLoading = true
                var params = {
                    order_num: that.order.order_num,
                    name: that.order.name,
                    training_video: that.order.training_video,
                    grant_file: that.order.grant_file,
                    type: 2,
                    conversion_code: that.exchange_code,
                }
                that.$workbenApi.payModel(params).then(res => {
                    that.codeLoading = false
                    if (res.code == 1000) {
                        that.$succMsg('支付成功')
                        that.$emit('paySuccess')
                        that.closeBtn()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.status == 4) {
                that.codeLoading = true
                var params = {
                    order_num: this.order.order_num,
                    type: 2,
                    id: this.order.id,
                    conversion_code: that.exchange_code,
                }
                that.$digitalApi.modelBuy(params).then(res => {
                    that.codeLoading = false
                    if (res.code == 1000) {
                        that.$succMsg('支付成功')
                        that.$emit('renovate')
                        that.closeBtn()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.status == 5) {
                that.codeLoading = true
                var params = {
                    order_num: this.order.order_num,
                    code: that.exchange_code,
                }
                that.$workbenApi.audioCodePay(params).then(res => {
                    that.codeLoading = false
                    if (res.code == 1000) {
                        that.$succMsg('支付成功')
                        that.$emit('paySuccess')
                        that.closeBtn()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.status == 6) {
                that.codeLoading = true
                var params = {
                    order_num: this.order.order_num,
                    type: 2,
                    conversion_code: that.exchange_code,
                    id: this.order.id
                }
                that.$digitalApi.templateBuy(params).then(res => {
                    that.codeLoading = false
                    if (res.code == 1000) {
                        that.$succMsg('支付成功')
                        that.$emit('renovate')
                        that.closeBtn()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.status == 7) {
                that.codeLoading = true
                var params = {
                    order_num: this.order.order_num,
                    type: 2,
                    conversion_code: that.exchange_code,
                    id: this.order.id
                }
                that.$workbenApi.marketScriptBuy(params).then(res => {
                    that.codeLoading = false
                    if (res.code == 1000) {
                        that.$succMsg('支付成功')
                        that.$emit('renovate')
                        that.closeBtn()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 去除当前页面得数据
        cleanInfo() {
            this.status = 1
            this.order = {
                diamond: '',
                id: '',
                order_num: '',
                product_category: '',
                product_name: '',
                total_amount: '',
                valid: '',
            }
            this.pay_index = 1
            this.exchange_code = ''
        },
        // 关闭支付
        beforeCloseBtn() {
            this.$refs.dialogTip.openDialogBtn()
        },
        // 确认关闭
        closeBtn() {
            this.cleanInfo()
            this.dialogVisible = false
        },
        // 刷新
        renovateBtn(val) {
            if (this.status == 1) {
                //
                this.order.diamond = val
            } else if (this.status == 2) {
                //
                this.order.diamond_num = val
            }
            this.$emit('renovate')
        }
    },
}
</script>

<style lang='scss' scoped>
.pay_page {
    .pay_content {
        width: 100%;
        height: 415px;
        display: flex;
        justify-content: space-between;

        .left {
            box-sizing: border-box;
            width: 330px;
            height: 100%;
            padding: 20px 20px;
            border-radius: 10px;
            border: 1px solid #e3e3e3;

            .left_top {
                font-size: 15px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: $bColor3;
            }

            .title {
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: $bColor6;
            }

            .text {
                width: 100%;
                height: 26px;
                background: #f8f8f8;
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .t_txt {
                    padding-left: 10px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: $bColor3;
                }

                .t_tip {
                    cursor: pointer;
                    width: 42px;
                    height: 22px;
                    line-height: 22px;
                    text-align: center;
                    margin-right: 2px;
                    background: #ffffff;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: $redColor3;
                }
            }

            .title_two {
                padding-top: 20px;
            }

            .text_two {
                box-sizing: border-box;
                height: 200px;
                padding: 5px 10px;
                display: block;

                .t_txt {
                    display: block;
                    padding-left: 0;
                    line-height: 25px;
                }
            }
        }

        .right {
            width: 330px;
            height: 100%;
            .title {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: $bColor3;
            }
            .right_pay {
                width: 100%;
                margin-top: 12px;
                .pay_nav {
                    display: flex;
                    width: 100%;
                    height: 38px;
                    span {
                        cursor: pointer;
                        display: block;
                        width: 104px;
                        line-height: 38px;
                        text-align: center;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: $bColor3;
                        background: $backggroundColor2;
                    }
                    span:first-child {
                        border-radius: 10px 0 0 0;
                    }
                    span:last-child {
                        border-radius: 0 10px 0 0;
                    }
                    .nav_active {
                        color: $blueColor1;
                        background: $backggroundColor1;
                    }
                }
                .pay_input {
                    width: 100%;
                    height: 345px;
                    background: $backggroundColor1;
                    border-radius: 0px 10px 10px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    .input {
                        width: 190px;
                        height: 44px;
                        .el-input {
                            width: 100%;
                            height: 44px;
                            ::v-deep .el-input__inner {
                                height: 44px;
                                line-height: 44px;
                                text-align: center;
                                font-size: 15px;
                            }
                        }
                    }
                    .el-button {
                        margin-top: 20px;
                        padding: 0;
                        width: 82px;
                        height: 33px;
                        line-height: 33px;
                        font-size: 14px;
                    }
                }
                .pay_info {
                    position: relative;
                    width: 100%;
                    height: 345px;
                    background: $backggroundColor1;
                    border-radius: 0 10px 10px 10px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .info_tip {
                        width: 100%;
                        padding-top: 60px;
                        text-align: center;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 500;
                        color: $bColor3;
                    }

                    .info_price {
                        padding-top: 16px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            display: block;
                            width: 47px;
                            height: 47px;
                            margin-top: 5px;
                        }
                        span {
                            padding-left: 6px;
                            font-size: 32px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: $bColor3;
                        }
                    }
                    .info_yh {
                        padding-top: 29px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .txt {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: $bColor3;
                        }
                        img {
                            display: block;
                            width: 15px;
                            height: 15px;
                            margin-top: 2px;
                            margin-left: 10px;
                            margin-right: 5px;
                        }
                        .num {
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: $bColor3;
                        }
                    }
                    .info_btn {
                        padding-top: 34px;
                    }
                    .info_bot {
                        position: absolute;
                        bottom: 20px;
                        left: 0;
                        box-sizing: border-box;
                        width: 100%;
                        padding: 0 20px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .bot_left {
                            display: flex;
                            align-items: center;
                            .txt {
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: $bColor3;
                            }
                            img {
                                display: block;
                                width: 15px;
                                height: 15px;
                                margin-top: 2px;
                                margin-left: 10px;
                                margin-right: 5px;
                            }
                            .num {
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: $bColor3;
                            }
                        }
                        .bot_right {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            .txt {
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: $blueColor1;
                            }
                            .el-icon-d-arrow-right {
                                color: $blueColor1;
                                padding-left: 3px;
                                padding-top: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
