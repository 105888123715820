<!-- @Author: Yu_Bo -->
<template>
    <div class='digital_person' :class="classStr">
        <el-container class="digital_person_container">
            <el-aside width='86px' class="aside_left">
                <aside-nav></aside-nav>
            </el-aside>
            <el-container class="right_container">
                <el-header height='88px' class="vue_main">
                    <header-top></header-top>
                </el-header>
                <el-main class="scroll_bar">
                    <section class="main_content">
                        <transition name="fade-transform" mode="out-in">
                            <router-view :key="$route.path" class="vue_main" />
                        </transition>
                    </section>
                </el-main>
            </el-container>
        </el-container>
        <!-- 右下角客服 -->
        <lower-right-corner v-if="bgShow != 2"></lower-right-corner>
    </div>
</template>

<script>
import { mapState } from "vuex"
import AsideNav from './components/aside_nav.vue'//左侧
import HeaderTop from './components/header_top.vue'//右侧顶部
import LowerRightCorner from '@/components/lower_right_corner/lower_right_corner.vue'//客服
export default {
    name: "FrameMain",
    components: { AsideNav, HeaderTop, LowerRightCorner },
    props: {},
    data() {
        return {

        }
    },
    computed: {
        ...mapState({
            bgShow: state => state.bgShow,
        }),
        classStr() {
            if (this.bgShow == 1) {
                return 'digital_bg_home'
            }
            if (this.bgShow == 2 || this.bgShow == 3) {
                return 'digital_bg_work'
            }
            if (this.bgShow == 4 || this.bgShow == 5) {
                return 'digital_bg_wo'
            }
        },
        key() {
            return this.$route.path;
        },
    },
    watch: {},
    created() {
        this.$store.dispatch('asideNav/routeMenu')
    },
    mounted() { },
    methods: {},
}
</script>

<style lang='scss' scoped>
.digital_person {
    width: 100%;
    height: 100%;
    background: #f7f7f7;

    .digital_person_container {
        width: 100%;
        height: 100%;

        .aside_left {
            overflow: visible;
            background: url(~@/assets/images/background/left_background.png) no-repeat;
            background-size: 100% 100%;
        }

        .right_container {
            padding: 0 20px 20px;
        }

        .el-header {
            padding: 20px 0;
        }

        .el-main {
            width: 100%;
            height: 100%;
            padding: 0;
        }

        .main_content {
            width: 100%;
            height: 100%;
        }
    }
}

.digital_bg_home {
    background: url(~@/assets/images/background/main_background_home.jpg) no-repeat;
    background-size: 100% 100%;
}

.digital_bg_work {
    background: url(~@/assets/images/background/main_background_work.jpg) no-repeat;
    background-size: 100% 100%;
}

.digital_bg_wo {
    background: url(~@/assets/images/background/main_background_wo.jpg) no-repeat;
    background-size: 100% 100%;
}
</style>
