<!-- @Author: Yu_Bo -->
<template>
    <div>
        <el-dialog class='membership_interests zc_dialog_inv' :visible.sync="dialogVisible" width="1000px" top='5vh'
            :close-on-click-modal='false' append-to-body>
            <div class="int_top">
                <div class="top_title">云川会员权益明细</div>
                <div class="top_main">
                    <img class="right_img" src="@/assets/images/background/interests_background_mild.png" alt="">
                </div>
            </div>
            <div class="int_main">
                <div class="main_box scroll_bar_x">
                    <div class="top_main_info">
                        <div class="info_text">
                            <div class="txt">相关权益</div>
                        </div>
                        <div class="info_text">
                            <div class="txt">普通版</div>
                            <div class="tip">免费</div>
                            <el-button size="small" class="btnBgColor_blue_empty_no" type="primary"
                                @click="workBtn">前往工作台</el-button>
                        </div>
                        <div class="info_text" v-for="(item, index) in list" :key="index">
                            <div class="txt">{{ item.name }}</div>
                            <div class="tip">¥{{ item.current_price }}</div>
                            <el-button size="small" class="btnBgColor_blue" type="primary"
                                @click="openMemberBtn(index)">立即开通</el-button>
                        </div>
                    </div>
                    <div class="main_text">
                        <div class="info_text">
                            <div class="text" style="height: 10px;"></div>
                            <div class="text" style="height: 10px;"></div>
                            <div class="text" style="height: 10px;" v-for="(item, index) in list" :key="index"></div>
                        </div>
                    </div>
                    <div class="main_title"><span>数字人使用权限</span></div>
                    <div class="main_text">
                        <div class="info_text">
                            <div class="text">免费数字人</div>
                            <div class="text"><i class="el-icon-check"></i></div>
                            <div class="text" v-for="(item, index) in list" :key="index"><i class="el-icon-check"></i></div>
                        </div>
                        <div class="info_text">
                            <div class="text">会员专属模特</div>
                            <div class="text"><i class="el-icon-close"></i></div>
                            <div class="text" v-for="(item, index) in list" :key="index"><i class="el-icon-check"></i></div>
                        </div>
                    </div>
                    <div class="main_title">声音使用权限</div>
                    <div class="main_text">
                        <div class="info_text">
                            <div class="text">免费声音</div>
                            <div class="text"><i class="el-icon-check"></i></div>
                            <div class="text" v-for="(item, index) in list" :key="index"><i class="el-icon-check"></i></div>
                        </div>
                    </div>
                    <div class="main_title">赠送权益</div>
                    <div class="main_text">
                        <div class="info_text">
                            <div class="text">视频制作时长</div>
                            <div class="text">5分钟</div>
                            <div class="text" v-for="(item, index) in list" :key="index">
                                <div v-if="item.rights.video">
                                    {{ item.rights.video.unit_text }}/{{ item.rights.video.card_type_text }}<span>{{ item.rights.video.valid_text }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="info_text">
                            <div class="text">音频制作时长</div>
                            <div class="text">10分钟</div>
                            <div class="text" v-for="(item, index) in list" :key="index">
                                <div v-if="item.rights.audio">
                                    {{ item.rights.audio.unit_text }}/{{ item.rights.audio.card_type_text }}<span>{{ item.rights.audio.valid_text }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="info_text">
                            <div class="text">AI绘画次数</div>
                            <div class="text"><i class="el-icon-close"></i></div>
                            <div class="text" v-for="(item, index) in list" :key="index">
                                <div v-if="item.rights.draw">
                                    {{ item.rights.draw.unit_text }}/{{ item.rights.draw.card_type_text }}<span>{{ item.rights.draw.valid_text }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="info_text">
                            <div class="text">AI漫画次数</div>
                            <div class="text"><i class="el-icon-close"></i></div>
                            <div class="text" v-for="(item, index) in list" :key="index">
                                <div v-if="item.rights.cartoon">
                                    {{ item.rights.cartoon.unit_text }}/{{ item.rights.cartoon.card_type_text }}<span>{{ item.rights.cartoon.valid_text }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main_title">功能特权</div>
                    <div class="main_text">
                        <div class="info_text">
                            <div class="text">视频去水印</div>
                            <div class="text"><i class="el-icon-check"></i></div>
                            <div class="text" v-for="(item, index) in list" :key="index"><i class="el-icon-check"></i></div>
                        </div>
                        <div class="info_text">
                            <div class="text">视频下载</div>
                            <div class="text"><i class="el-icon-check"></i></div>
                            <div class="text" v-for="(item, index) in list" :key="index"><i class="el-icon-check"></i></div>
                        </div>
                        <div class="info_text">
                            <div class="text">绘画下载</div>
                            <div class="text"><i class="el-icon-check"></i></div>
                            <div class="text" v-for="(item, index) in list" :key="index"><i class="el-icon-check"></i></div>
                        </div>
                        <div class="info_text">
                            <div class="text">音频下载</div>
                            <div class="text"><i class="el-icon-check"></i></div>
                            <div class="text" v-for="(item, index) in list" :key="index"><i class="el-icon-check"></i></div>
                        </div>
                        <div class="info_text">
                            <div class="text">漫画下载</div>
                            <div class="text"><i class="el-icon-check"></i></div>
                            <div class="text" v-for="(item, index) in list" :key="index"><i class="el-icon-check"></i></div>
                        </div>
                        <div class="info_text">
                            <div class="text">视频生成数量</div>
                            <div class="text"><i class="el-icon-check"></i></div>
                            <div class="text" v-for="(item, index) in list" :key="index">无限制</div>
                        </div>
                        <div class="info_text">
                            <div class="text">音频生成数量</div>
                            <div class="text"><i class="el-icon-check"></i></div>
                            <div class="text" v-for="(item, index) in list" :key="index">无限制</div>
                        </div>
                    </div>
                </div>
                <div class="int_bot">
                    <div class="bot_title">温馨提示:</div>
                    <div class="bot_txt">
                        1.本产品为虚拟商品，由于虚拟商品的性质和特征，会员开通后不记名、不挂失、不兑换现金、不退不换，请谨慎选择后购买。
                    </div>
                    <div class="bot_txt">
                        2.若开通会员后10分钟内标识仍然没有点亮，请退出帐号重新登录尝试或
                        <el-popover popper-class="popovre_text" placement="top" width="166" trigger="hover">
                            <div class="popover_box">
                                <div class="box_title">
                                    <img src="@/assets/images/img/header.png" alt="">
                                    <span>添加客服微信</span>
                                </div>
                                <div class="box_img">
                                    <img src="@/assets/images/img/service_img.png" alt="">
                                </div>
                            </div>
                            <span slot="reference">联系客服</span>
                        </el-popover>。
                    </div>
                    <div class="bot_txt">
                        3.开通会员则默认您已同意硅语平台<span @click="blankToBtn('membership')">《会员服务协议》</span>。
                    </div>
                    <div class="bot_txt">
                        4.结转即当前会员到期后剩余未使用的权益一直有效，不结转即当前会员套餐结束后剩余权益立即失效。
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            dialogVisible: false,
            list: []
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(arr) {
            this.list = arr
            this.dialogVisible = true
        },
        openMemberBtn(index) {
            this.$emit('open', index)
            this.dialogVisible = false
        },
        // 前往工作台
        workBtn() {
            this.dialogVisible = false
            this.$emit('close')
            this.$router.push('/workben/index')
        },
        // 会员服务协议
        blankToBtn(val) {
            const { href } = this.$router.resolve({
                path: "/textPage/" + val,
            });
            window.open(href, "_blank")
        }
    },
}
</script>

<style lang='scss' scoped>
.membership_interests {
    ::v-deep .el-dialog {
        border-radius: 20px;
        background: none;

        .el-dialog__headerbtn {
            z-index: 1;
        }

        .el-dialog__close {
            color: #333;
        }
    }

    .int_top {
        position: relative;
        width: 100%;
        height: 179px;
        background: url(~@/assets/images/background/interests_background_top.png) no-repeat;
        background-size: 100% 100%;
        border-radius: 20px 20px 0 0;

        .top_title {
            box-sizing: border-box;
            padding: 20px 30px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }

        .top_main {
            position: absolute;
            left: 30px;
            bottom: 0;
            width: calc(100% - 60px);
            height: 111px;
            background: linear-gradient(180deg, #DBE5FF 0%, rgba(255, 255, 255, 0.6) 100%);
            border-radius: 20px 20px 0px 0px;

            .right_img {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
                display: block;
                width: 118px;
                height: 100px;
            }
        }
    }

    .int_main {
        box-sizing: border-box;
        width: 100%;
        margin-top: -110px;
        padding: 0 30px 30px;
        border-radius: 0 0 20px 20px;
        background: #fff;

        .main_box {
            width: 100%;
            background: #F6F6F6;
            overflow-x: auto;
        }

        .top_main_info {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 110px;
            display: flex;
            align-items: center;

            .info_text {
                min-width: 188px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .txt {
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }

                .tip {
                    padding: 2px 0 6px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }

                .el-button {
                    padding: 9px 10px;
                }
            }
        }

        .main_title {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
        }

        .main_text {
            width: 100%;

            .info_text {
                width: 100%;
                display: flex;
                align-items: center;

                .text {
                    min-width: 188px;
                    height: 52px;
                    border-bottom: 1px solid #F2F2F2;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;

                    .el-icon-check {
                        font-size: 16px;
                        color: #00B278;
                    }

                    .el-icon-close {
                        font-size: 16px;
                        color: #FC0908;
                    }

                    span {
                        padding-left: 10px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FC0908;
                    }
                }
            }

            .info_text:last-child {
                border-bottom: none;
            }
        }

        .int_bot {
            width: 100%;
            padding-top: 20px;

            .bot_title {
                width: 100%;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }

            .bot_txt {
                padding-top: 5px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;

                span {
                    cursor: pointer;
                    color: #2E4BF2;
                }
            }
        }
    }
}</style>
