<!-- @Author: Yu_Bo -->
<template>
  <div>
    <div>
    	<el-dialog class='pay_page zc_dialog_box' :visible.sync="dialogVisible" width="720px" :close-on-click-modal='false' :before-close='beforeCloseBtn' :append-to-body="true">
    	  <div class="title" slot="title">
    	    <div class="name">支付</div>
    	  </div>
        <div class="pay_content" v-loading='loading'>
          <div class="left">
            <div class="title">订单号：</div>
            <div class="text">
              <span class="t_txt">{{order.order_num}}</span>
              <span class="t_tip" @click="copyBtn">复制</span>
            </div>
            <div class="title title_two">订单详情：</div>
            <div class="text text_two">
              <span class="t_txt">{{order.product_name}}</span>
            </div>
          </div>
          <div class="right">
            <div class="title">请选择付款方式：</div>
            <div class="right_pay">
              <div class="pay_nav">
                <span :class="pay_nav==1?'nav_active':''" @click="payNavBtn(1)">支付宝</span>
                <span :class="pay_nav==2?'nav_active':''" @click="payNavBtn(2)">微信</span>
              </div>
              <div class="pay_info">
                <div class="info_tip">应付金额</div>
                <div class="info_price">¥{{order.total_amount}}</div>
                <div class="info_img" id="ali" v-show="pay_nav==1">
                  <!-- <img v-show="pay_nav==2" :src="order.wx_pay_qrcode" alt=""> -->
                </div>
                <div class="info_img" id='wx' v-show="pay_nav==2">
                  <!-- <img v-show="pay_nav==2" :src="order.wx_pay_qrcode" alt=""> -->
                </div>
              </div>
            </div>
          </div>
        </div>
    	</el-dialog>
    </div>
    <!--  -->
    <point-out ref='dialogTip' @close='closeBtn' type='pay' dialogTitle="提示" dialogWidth='380px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">请确认是否放弃支付？</div>
      </div>
    </point-out>
  </div>
</template>

<script>
  import QRCode from 'qrcodejs2'
  import PointOut from '@/components/point_out/point_out.vue'//温馨提示弹框
	export default {
		components: {
      PointOut
    },
		props: {},
		data() {
			return{
				dialogVisible:false,
        loading:false,
        order:{
          ali_pay_qrcode:'',
          diamond:'',
          id:'',
          order_num:'',
          product_category:'',
          product_name:'',
          total_amount:'',
          wx_pay_qrcode:'',
        },
        pay_nav:1,
        //
        timer:null
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(obj){
        this.pay_nav=1
        this.order=''
        this.dialogVisible=true
        this.$nextTick(function(){
          document.getElementById('ali').innerHTML = ""
          document.getElementById('wx').innerHTML = ""
          this.orderInfo(obj)
        })
      },
      orderInfo(obj){
        var that = this
        that.loading=true
        var params = {
          product_category:obj.type,
          diamond_number:obj.diamond_number,
          earn_number:obj.earn_number,
          earn_unit:obj.earn_unit,
          order_source:1,
          id:obj.id
        }
        that.$payApi.rechargeOrder(params).then(res => {
          that.loading=false
          if (res.code == 1000) {
            that.order=res.result
            that.generateQRCode('#ali',that.order.ali_pay_qrcode)
            that.orderStatus()
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      payNavBtn(type){
        this.pay_nav=type
        document.getElementById('ali').innerHTML = ""
        document.getElementById('wx').innerHTML = ""
        if(type==1){
          this.generateQRCode('#ali',this.order.ali_pay_qrcode)
        }else{
          this.generateQRCode('#wx',this.order.wx_pay_qrcode)
        }
      },
      // 生成二维码
      generateQRCode(classId,url) {
        let qrcode = new QRCode(document.querySelector(classId), {
          text: url,
          width: 178,
          height: 178,
        })
      },
      // 轮询查询订单状态
      orderStatus(){
        var that = this
        that.timer = setInterval(() => {
          var params = {
            order_num:that.order.order_num,
          }
          that.$payApi.checkOrderStatus(params).then(res => {
            if (res.code == 1000) {
              that.$succMsg('支付成功')
              that.$store.dispatch('userInfo/getUserInfo')
              that.$emit('renovate')
              that.closeBtn()
            }
          })
        }, 3000)
      },
      // 复制订单号
      copyBtn(){
        this.$copyText(this.order.order_num).then((item)=>{
          this.$succMsg('复制成功')
        })
      },
      // 关闭支付
      beforeCloseBtn(){
        this.$refs.dialogTip.openDialogBtn()
      },
      // 确认关闭
      closeBtn(){
        clearInterval(this.timer)
        this.timer=null
        this.dialogVisible=false
      },
    },
	}
</script>

<style lang='scss' scoped>
	.pay_page{
    .pay_content{
      width: 100%;
      height: 415px;
      display: flex;
      justify-content: space-between;
      .left{
        box-sizing: border-box;
        width: 330px;
        height: 100%;
        padding: 20px 20px;
        border-radius: 10px;
        border: 1px solid #E3E3E3;

        .left_top {
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: $bColor3;
        }

        .title {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: $bColor6;
        }

        .text {
          width: 100%;
          height: 26px;
          background: #F8F8F8;
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .t_txt {
            padding-left: 10px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: $bColor3;
          }

          .t_tip {
            cursor: pointer;
            width: 42px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            margin-right: 2px;
            background: #FFFFFF;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: $redColor3;
          }
        }

        .title_two {
          padding-top: 20px;
        }

        .text_two {
          box-sizing: border-box;
          height: 200px;
          padding: 5px 10px;
          display: block;

          .t_txt {
            display: block;
            padding-left: 0;
            line-height: 25px;
          }
        }
      }
      .right{
        width: 330px;
        height: 100%;
        .title{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: $bColor3;
          line-height: 20px;
        }
        .right_pay{
          width: 100%;
          height: 383px;
          margin-top: 12px;
          .pay_nav{
            display: flex;
            width: 100%;
            height: 38px;
            span{
              cursor: pointer;
              display: block;
              width: 104px;
              line-height: 38px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: $bColor3;
              background: $backggroundColor2;
            }
            span:first-child{
              border-radius: 10px 0 0 0;
            }
            span:last-child{
              border-radius: 0 10px 0 0;
            }
            .nav_active{
              color: $blueColor1;
              background: $backggroundColor1;
            }
          }
          .pay_info{
            width: 100%;
            height: 345px;
            background: $backggroundColor1;
            border-radius: 0px 10px 10px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .info_tip{
              width: 100%;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: $bColor3;
            }
            .info_price{
              padding: 10px 0 20px;
              width: 100%;
              text-align: center;
              font-size: 26px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: $redColor2;
            }
            .info_img{
              box-sizing: border-box;
              width: 200px;
              height: 200px;
              padding: 10px;
              background: #FFFFFF;
              border-radius: 10px;
              border: 1px solid $borderColor1;
              img{
                display: block;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
	}
</style>
