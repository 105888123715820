<!-- @Author: Yu_Bo -->
<template>
    <div>
        <div>
            <el-dialog class="open_member zc_dialog_inv" :visible.sync="dialogVisible" width="1000px" top='5vh'
                @close='closeBtn' :close-on-click-modal='false' :append-to-body="true">
                <div class="member_main">
                    <!--  -->
                    <div class="user_info">
                        <img :src="userInfo.head_pic ? userInfo.head_pic : require('@/assets/images/img/header.png')" alt="">
                        <div class="info_txt">
                            <span class="nick_name">{{ userInfo.nickname || userInfo.mobile }}</span>
                            <span class="account">账号：{{ userInfo.mobile }}</span>
                        </div>
                    </div>
                    <!--  -->
                    <div class="explain_btn">
                        <div class="btn_txt" @click="memberShipBtn">
                            云川会员权益说明<i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div v-loading='loading'>
                        <!--  -->
                        <div class="member_box scroll_bar_x">
                            <div class="box_list" :class="member_index == index ? 'box_list_active' : ''"
                                v-for="(item, index) in member_list" :key="index" @click="memberBtn(index)">
                                <div>
                                    <div class="name">{{ item.name }}</div>
                                    <div class="price">￥<span>{{ item.current_price }}</span></div>
                                    <div class="money">约{{ item.day_month }}/天<span>原价￥{{ item.original_price }}</span></div>
                                </div>
                                <div class="box_right">
                                    <div class="right_txt">
                                        <i class="el-icon-success"></i>
                                        <span class="txt">会员VIP专属数字人</span>
                                    </div>
                                    <div class="right_txt">
                                        <i class="el-icon-success"></i>
                                        <span class="txt">会员VIP专属声音</span>
                                    </div>
                                    <div class="right_txt" v-for="(ite, key, idx) in item.rights" :key="key" :id="idx">
                                        <i class="el-icon-success"></i>
                                        <span class="txt">{{ ite.unit_text }} {{ ite.product_name }} </span>
                                        <span class="tip">（{{ ite.valid_text }}）</span>
                                    </div>
                                </div>
                                <img class="top_right" src="@/assets/images/icon/member_icon.png" alt="">
                            </div>
                        </div>
                        <!--  -->
                        <div class="member_time" v-if="member_list.length">注：购买当前会员预计
                            {{ member_list[member_index].expire_date }} 到期</div>
                        <!--  -->
                        <div class="member_pay">
                            <div class="pay_left" :id="type == 'main' ? 'payMain' : 'payTop'" v-loading='payLoading'></div>
                            <div class="pay_right" v-if="member_list.length">
                                <div class="right_top"><span>￥</span>{{ member_list[member_index].current_price }}</div>
                                <div class="right_type">
                                    <div class="type_list" :class="pay_index == index ? 'active' : ''"
                                        v-for="(item, index) in pay_list" :key="index" @click="payTypeBtn(index)">
                                        <img :src="item.img" alt="">
                                        <span>{{ item.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div class="member_tip">
                            会员套餐为虚拟服务，购买后不支持退换，请慎重选择。支付即视为同意<span @click="blankToBtn('membership')">《会员服务协议》</span>
                        </div>
                        <!--  -->
                        <div class="member_exchange">
                            <div class="exc_input">
                                <el-input v-model="exchange_number" placeholder="请输入兑换码"></el-input>
                            </div>
                            <el-button class="btnBgColor_blue" :loading='exLoading' type="primary"
                                @click="redeemBtn">立即兑换</el-button>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </div>
        <!-- 会员权益 -->
        <membership-interests ref='dialogInterests' @close='closeBtn' @open='memberBtn'></membership-interests>
        <!-- 钻石支付 -->
        <pay-diamond ref='dialogdiamond' @renovate='taocanInfo'></pay-diamond>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import { mapState } from "vuex"
import MembershipInterests from '@/components/membership_interests/membership_interests.vue'//会员权益
import PayDiamond from '@/components/pay_page/pay_diamond.vue'//钻石支付
export default {
    components: { MembershipInterests, PayDiamond },
    props: {},
    data() {
        return {
            type: 'main',
            dialogVisible: false,
            loading: false,
            member_list: [],
            member_index: 0,
            pay_list: [
                { img: require('@/assets/images/icon/wx_icon.png'), name: '微信支付' },
                { img: require('@/assets/images/icon/alipay_icon.png'), name: '支付宝' },
                { img: require('@/assets/images/icon/diamond_icon.png'), name: '钻石支付' },
            ],
            pay_index: 0,
            orderInfo: null,
            payLoading: false,
            timer: null,
            outTradeNo: '',
            // 兑换
            exchange_number: '',
            exLoading: false,
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo.user_info, // 用户信息
        }),
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(val) {
            if (val) {
                this.type = val
            }
            this.dialogVisible = true
            this.member_index = 0
            this.pay_index = 0
            clearInterval(this.timer)
            this.timer = null
            this.$nextTick(function () {

                this.taocanInfo()
            })
        },
        // 获取套餐
        taocanInfo() {
            var that = this
            that.loading = true
            that.$payApi.rechargeVipList().then(res => {
                that.loading = false
                if (res.code == 1000) {
                    if (that.type == 'main') {
                        document.getElementById('payMain').innerHTML = ""
                    } else {
                        document.getElementById('payTop').innerHTML = ""
                    }
                    that.member_list = res.result.data
                    that.orderForm()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 微信/支付宝生成订单
        orderForm() {
            var that = this
            var params = {
                vip_id: that.member_list[that.member_index].id,
                source: 1,
                pay_type: that.pay_index == 0 ? 1 : 2
            }
            that.$payApi.createRechargeOrder(params).then(res => {
                if (res.code == 1000) {
                    that.orderInfo = res.result
                    that.getCreateQrcode()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        getCreateQrcode() {
            var that = this
            that.payLoading = true
            var params = {
                order_name: that.orderInfo.order_name,
                order_num: that.orderInfo.order_num,
                pay_type: that.pay_index == 0 ? 1 : 2
            }
            that.$payApi.createQrcode(params).then(res => {
                if (res.code == 1000) {
                    that.outTradeNo = res.result.outTradeNo
                    if (this.type == 'main') {
                        that.generateQRCode('#payMain', res.result.qrCode)
                    } else {
                        that.generateQRCode('#payTop', res.result.qrCode)
                    }
                    that.orderStatus()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 生成二维码
        generateQRCode(classId, url) {
            let qrcode = new QRCode(document.querySelector(classId), {
                text: url,
                width: 100,
                height: 100,
            })
            this.payLoading = false
        },
        // 轮询查询订单状态
        orderStatus() {
            var that = this
            that.timer = setInterval(() => {
                var params = {
                    order_num: that.outTradeNo,
                }
                that.$payApi.queryOrder(params).then(res => {
                    if (res.code == 1000) {
                        if (res.result.pay_type == '兑换码') {
                            // 兑换码支付 不需要弹提示框
                        } else {
                            that.$succMsg('支付成功')
                            that.$store.dispatch('userInfo/getUserInfo')
                            that.closeBtn()
                        }
                    }
                })
            }, 3000)
        },
        // 关闭
        closeBtn() {
            clearInterval(this.timer)
            this.timer = null
            this.exchange_number = ''
            this.outTradeNo = ''
            this.orderInfo = null
            this.dialogVisible = false
        },
        // 选择会员
        memberBtn(index) {
            if (this.type == 'main') {
                document.getElementById('payMain').innerHTML = ""
            } else {
                document.getElementById('payTop').innerHTML = ""
            }
            this.member_index = index
            this.orderForm()
        },
        // 选择支付
        payTypeBtn(index) {
            if (index == 2) {
                this.$refs.dialogdiamond.openDialogBtn(this.member_list[this.member_index], 2)
                return
            } else {
                if (this.type == 'main') {
                    document.getElementById('payMain').innerHTML = ""
                } else {
                    document.getElementById('payTop').innerHTML = ""
                }
                this.pay_index = index
                this.getCreateQrcode()
            }
        },
        // 会员权益
        memberShipBtn() {
            this.$refs.dialogInterests.openDialogBtn(this.member_list)
        },
        // 会员服务协议
        blankToBtn(val) {
            const { href } = this.$router.resolve({
                path: "/textPage/" + val,
            });
            window.open(href, "_blank")
        },
        // 兑换
        redeemBtn() {
            var that = this
            if (that.exchange_number == '') {
                that.$errMsg('请输入兑换码')
            } else {
                that.exLoading = true
                var params = {
                    order_num: that.outTradeNo,
                    code: that.exchange_number,
                    resource: 1,
                }
                that.$payApi.exchangeMemberCode(params).then(res => {
                    that.exLoading = false
                    if (res.code == 1000) {
                        that.$succMsg('支付成功')
                        that.$store.dispatch('userInfo/getUserInfo')
                        that.closeBtn()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
    },
}
</script>

<style lang='scss' scoped>
.open_member {
    ::v-deep .el-dialog {
        border-radius: 20px;
        background: none;

        .el-dialog__close {
            color: #333;
        }
    }

    .member_main {
        box-sizing: border-box;
        padding: 40px 30px;
        width: 100%;
        border-radius: 20px 20px;
        background: url(~@/assets/images/background/member_background.png) no-repeat;
        background-size: 100% 100%;

        .user_info {
            width: 100%;
            display: flex;

            img {
                display: block;
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }

            .info_txt {
                padding-left: 10px;
                height: 60px;
                display: flex;
                justify-content: center;
                flex-direction: column;

                .nick_name {
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }

                .account {
                    padding-top: 6px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666;
                }
            }
        }

        .explain_btn {
            width: 100%;
            padding-top: 6px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .btn_txt {
                cursor: pointer;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2E4BF2;
            }
        }

        .member_box {
            box-sizing: border-box;
            width: 100%;
            padding-bottom: 6px;
            margin-top: 14px;
            overflow-x: auto;
            display: flex;

            .box_list {
                cursor: pointer;
                position: relative;
                min-width: 320px;
                margin-right: 20px;
                height: 484px;
                padding: 10px;
                background: url(~@/assets/images/background/member_background_a.png) no-repeat;
                background-size: 100% 100%;
                border-radius: 10px;
                overflow: hidden;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                .top_right {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 2;
                    display: none;
                    width: 50px;
                    height: 50px;
                }

                .name {
                    padding-top: 20px;
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }

                .price {
                    padding: 20px 0 10px;
                    width: 100%;
                    text-align: center;
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;

                    span {
                        font-size: 58px;
                    }
                }

                .money {
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;

                    span {
                        padding-left: 5px;
                        text-decoration: line-through;
                    }
                }

                .box_right {
                    box-sizing: border-box;
                    width: 100%;
                    height: 248px;
                    padding: 10px 10px;
                    background: rgba(255, 255, 255, 0.9);
                    border-radius: 10px;
                    display: flex;
                    /* justify-content: space-around; */
                    flex-direction: column;

                    .right_txt {
                        margin-top: 10px;
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;

                        .el-icon-success {
                            color: #2E4BF2;
                            font-size: 18px;
                            margin-top: -2px;
                        }

                        .txt {
                            padding-left: 5px;
                            font-size: 13px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }

                        .tip {
                            font-size: 13px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #E00C25;
                        }
                    }
                }
            }

            .box_list:last-child {
                margin-right: 0;
            }

            .box_list_active {
                background: url(~@/assets/images/background/member_background_b.png) no-repeat;
                background-size: 100% 100%;

                .top_right {
                    display: block;
                }
            }
        }

        .member_time {
            box-sizing: border-box;
            width: 100%;
            padding: 20px 0 20px 10px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2E4BF2;
        }

        .member_pay {
            box-sizing: border-box;
            padding-left: 10px;
            width: 100%;
            height: 120px;
            display: flex;

            .pay_left {
                box-sizing: border-box;
                width: 120px;
                height: 120px;
                padding: 10px;
                background: #fff;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            .pay_right {
                padding-left: 16px;
                height: 120px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                .right_top {
                    display: flex;
                    align-items: center;
                    font-size: 32px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #E00C25;

                    span {
                        font-size: 24px;
                        margin-top: 5px;
                    }
                }

                .right_type {
                    display: flex;

                    .type_list {
                        cursor: pointer;
                        box-sizing: border-box;
                        margin-right: 20px;
                        width: 128px;
                        height: 48px;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #E2E2E2;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            display: block;
                            width: 28px;
                            height: 28px;
                        }

                        span {
                            padding-left: 8px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }
                    }

                    .active {
                        border: 1px solid #2E4BF2;
                    }
                }
            }
        }

        .member_tip {
            width: 100%;
            box-sizing: border-box;
            padding: 14px 0 20px 10px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;

            span {
                cursor: pointer;
                color: #2E4BF2;
            }
        }

        .member_exchange {
            width: 100%;
            box-sizing: border-box;
            padding-left: 10px;
            display: flex;

            .exc_input {
                width: 200px;
                margin-right: 14px;
            }
        }
    }
}

.tip_txt {
    width: 410px;
    line-height: 19px;
}
</style>
