import router from '../../router'
// 不需要添加顶部导航的name
const not_list = [
  'login',
  'helpOperate',
  'helpProblem',
  'helpTutorials',
  'textPage',
  'callUp',
  'settleIn',
  'addEdit',
  '400',
  '403',
  '404',
  '500',
  'messageDetails',
  'session'
]
// 需要添加顶部导航的二级页面的key
const url_list = [
  'digital_edit',
  'sound_edit',
  'model_details',
  'person_member',
  'video_details',
  'audio_details',
  'painting_details',
  'comics_details',
  'drama_details',
  'album_details',
  'workben_drama',
  'workben_drama_album',
  'create_audio',
  'create_video',
]
const tabsArr = {
  namespaced: true,
  state: { // 储存的数据
    tabKey: localStorage.getItem('tabKey') || '',
    tabList: JSON.parse(localStorage.getItem('tabList')) || []
  },
  mutations: { // 修改
    // 储存
    SET_TABSARR(state, str) {
      state.tabList = str
      localStorage.tabList = JSON.stringify(str)
    },
    SET_TABKEY(state, val) {
      state.tabKey = val
      localStorage.tabKey = val
    },
  },
  actions: { // 发起异步请求
    addTabsArr({
      commit,
      state,
      dispatch
    }, to) {
      var notUrl = not_list.find(item => item === to.name)
      if (notUrl) {
        return
      }
      var arr = state.tabList || []
      var obj = arr.find(item => item.url === to.path)
      if (obj) {
        // 存在直接跳转
        commit('SET_TABKEY', obj.key)
        if (obj.parentKey) {
          commit('asideNav/SET_NAVKEY', obj.parentKey, {
            root: true
          })
          commit('asideNav/SET_NAVTWOKEY', obj.key || '', {
            root: true
          })
        } else {
          commit('asideNav/SET_NAVKEY', obj.key, {
            root: true
          })
          commit('asideNav/SET_NAVTWOKEY', '', {
            root: true
          })
        }
      } else {
        // 不存在，先添加在跳转
        var key = to.meta.key
        var str = url_list.find(item => item === key)
        if (str) {
          if (str == 'workben_drama' || str == 'workben_drama_album') {
            arr.push({
              key: key + '_' + to.params.id,
              parentKey: to.meta.parentKey || '',
              name: to.meta.title,
              ids: to.query.id,
              url: to.path
            })
          } else {
            arr.push({
              key: key + '_' + to.params.id,
              parentKey: to.meta.parentKey || '',
              name: to.meta.title,
              name_txt: to.query.name,
              url: to.path
            })
          }
          commit('SET_TABSARR', arr)
          commit('SET_TABKEY', key + '_' + to.params.id)
        } else {
          arr.push({
            key: key,
            parentKey: to.meta.parentKey || '',
            name: to.meta.title,
            url: to.path
          })
          commit('SET_TABSARR', arr)
          commit('SET_TABKEY', key)
        }
        if (to.meta.parentKey) {
          commit('asideNav/SET_NAVKEY', to.meta.parentKey, {
            root: true
          })
          commit('asideNav/SET_NAVTWOKEY', key || '', {
            root: true
          })
        } else {
          commit('asideNav/SET_NAVKEY', key, {
            root: true
          })
          commit('asideNav/SET_NAVTWOKEY', '', {
            root: true
          })
        }
      }
    },
    // 修改导航标题名称
    getName({
      commit,
      state
    }, params) {
      var arr_list = state.tabList
      arr_list.forEach(item => {
        if (item.key === params.key) {
          item.name = params.name
        }
      })
      commit('SET_TABSARR', arr_list)
    },
    // 删除导航
    delTabList({
      commit,
      state
    }) {
      var arr_list = state.tabList
      var key = state.tabKey
      arr_list.forEach((item, index) => {
        if (item.key === key) {
          arr_list.splice(index, 1)
          var obj = arr_list[index - 1]
          if (obj.name_txt) {
            router.push({
              path: obj.url,
              query: {
                name: obj.name_txt
              }
            })
          } else if (obj.ids) {
            router.push({
              path: obj.url,
              query: {
                id: obj.ids
              }
            })
          } else {
            router.push(obj.url)
          }
        }
      })
      commit('SET_TABSARR', arr_list)
    }
  },

};
export default tabsArr;
