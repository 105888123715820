// 作品管理api
import axios from '../request/request.js'

export default {
  // 作品管理====绘画作品 统计、参数
  getDrawParams(data) {
    return axios({
      url: "/member/draw/params",
      method: "get",
      data,
    });
  },
  // 作品管理====绘画作品 列表
  getDrawList(data) {
    return axios({
      url: "/member/draw",
      method: "get",
      data,
    });
  },
  // 作品管理====绘画作品 详情
  getDrawDetails(data) {
    return axios({
      url: "/member/draw/" + data,
      method: "get",
      // data,
    });
  },
  // 作品管理====绘画作品 删除
  delDraw(data) {
    return axios({
      url: "/member/draw/" + data,
      method: "delete",
      // data,
    });
  },
  // 作品管理====绘画作品 编辑名称
  editDrawName(data, id) {
    return axios({
      url: "/member/draw/" + id,
      method: "put",
      data,
    });
  },
  // 作品管理====绘画作品 新建
  addDraw(data) {
    return axios({
      url: "/member/draw",
      method: "post",
      data,
    });
  },
  // 作品管理====绘画作品 上传参考图
  uploadDraw(data) {
    return axios({
      url: "/member/draw/upload",
      method: "post",
      data,
      'Headers': {
        'Content-Type': 'multipart/form-data'
      }

    });
  },
  // 作品管理====绘画作品 灵感引用列表
  inspirationList(data) {
    return axios({
      url: "/member/draw/inspiration",
      method: "get",
      data,
    });
  },
  // 作品管理====漫画作品 统计、参数
  getCartoonParams(data) {
    return axios({
      url: "/member/cartoon/params",
      method: "get",
      data,
    });
  },
  // 作品管理====漫画作品 列表
  getCartoonList(data) {
    return axios({
      url: "/member/cartoon",
      method: "get",
      data,
    });
  },
  // 作品管理====漫画作品 详情
  getCartoonDetails(data) {
    return axios({
      url: "/member/cartoon/" + data,
      method: "get",
      // data,
    });
  },
  // 作品管理====漫画作品 删除
  delCartoon(data) {
    return axios({
      url: "/member/cartoon/" + data,
      method: "delete",
      // data,
    });
  },
  // 作品管理====漫画作品 编辑名称
  editCartoonName(data, id) {
    return axios({
      url: "/member/cartoon/" + id,
      method: "put",
      data,
    });
  },
  // 作品管理====漫画作品 新建
  addCartoon(data) {
    return axios({
      url: "/member/cartoon",
      method: "post",
      data,
    });
  },
  // 作品管理====视频作品 统计
  getVideoStat(data) {
    return axios({
      url: "/member/video/stat",
      method: "get",
      data,
    });
  },
  // 作品管理====视频作品 参数
  getVideoParams(data) {
    return axios({
      url: "/member/video/params",
      method: "get",
      data,
    });
  },
  // 作品管理====视频作品 列表
  getVideoList(data) {
    return axios({
      url: "/member/video",
      method: "get",
      data,
    });
  },
  // 作品管理====视频作品 详情
  getVideoDetails(data) {
    return axios({
      url: "/member/video/" + data,
      method: "get",
      // data,
    });
  },
  // 作品管理====视频作品 删除
  delVideo(data) {
    return axios({
      url: "/member/video/" + data,
      method: "delete",
      // data,
    });
  },
  // 作品管理====视频作品 编辑名称
  editVideoName(data, id) {
    return axios({
      url: "/member/video/" + id,
      method: "put",
      data,
    });
  },
  // 作品管理====视频作品 添加标签
  addVideoCategory(data) {
    return axios({
      url: "/member/video_category",
      method: "post",
      data,
    });
  },
  // 作品管理====视频作品 删除标签
  delVideoCategory(data) {
    return axios({
      url: "/member/video/delete_video_category",
      method: "post",
      data,
    });
  },
  // 作品管理====音频作品 统计
  getAudioStat(data) {
    return axios({
      url: "/member/tts_create/statistic",
      method: "get",
      data,
    });
  },
  // 作品管理====音频作品 参数
  getAudioParams(data) {
    return axios({
      url: "/member/tts_create/params",
      method: "get",
      data,
    });
  },
  // 作品管理====音频作品 列表
  getAudioList(data) {
    return axios({
      url: "/member/tts_create",
      method: "get",
      data,
    });
  },
  // 作品管理====音频作品 详情
  getAudioDetails(data) {
    return axios({
      url: "/member/tts_create/" + data,
      method: "get",
      // data,
    });
  },
  // 作品管理====音频作品 删除
  delAudio(data) {
    return axios({
      url: "/member/tts_create/" + data,
      method: "delete",
      // data,
    });
  },
  // 作品管理====音频作品 编辑名称
  editAudioName(data, id) {
    return axios({
      url: "/member/tts_create/" + id,
      method: "put",
      data,
    });
  },
  // 名片市场 上传头像  后端检测是否有人脸
  uploadAvatar(data) {
    return axios({
      url: "/member/market_card/up_head_url",
      method: "post",
      data,
      'Headers': {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
}
