// 创建音频
import axios from '../request/request.js'

export default {
  // 创建音频
  quickCreate(data) {
    return axios({
      url: "/member/tts_create/quick_create",
      method: "post",
      data,
    });
  },
  // 音频制作参数
  ttsCreateParams(data) {
    return axios({
      url: "/member/tts_create/params",
      method: "get",
      data,
    });
  },
  // 编辑音频名称
  putTtsCreate(data, id) {
    return axios({
      url: "/member/tts_create/" + id,
      method: "put",
      data,
    });
  },
  // 试听
  ttsCreateAudition(data) {
    return axios({
      url: "/member/tts_create/audition",
      method: "post",
      data,
      responseType: 'blob'
    });
  },
  // 仅保存
  ttsCreate(data) {
    return axios({
      url: "/member/tts_create",
      method: "post",
      data,
    });
  },
  // 模型标签筛选
  ttsListSelect(data) {
    return axios({
      url: "/member/tts_list/select",
      method: "get",
      data,
    });
  },
  // 上传文档 - 内容解析
  ttsCreateReadText(data) {
    return axios({
      url: "/member/tts_create/read_text",
      method: "post",
      data,
      'Headers': {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  // 合成
  ttsCreateSynthesis(data) {
    return axios({
      url: "/member/tts_create/synthesis",
      method: "post",
      data,
    });
  },
  // 音频提取字幕
  voiceToSubtitle(data) {
    return axios({
      url: "/member/video/get_time_voice",
      method: "post",
      data,
    });
  },
}
