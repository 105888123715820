import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import {
  FrameMain
} from '../frame'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => {})
};

Vue.use(VueRouter)
// bgShow=6 无背景
const routes = [{
    path: '*',
    redirect: '/login',
    meta: {
      title: "登录",
      key: '',
      bgShow: 6
    }, //bgShow-控制背景的变化
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: "登录",
      key: '',
      bgShow: 6
    }, //bgShow-控制背景的变化
    component: () => import('@/views/login/login.vue'), //登录
  },
  {
    path: '/textPage/:txt',
    name: 'textPage',
    meta: {
      title: "云川",
      key: '',
      bgShow: 6
    }, //bgShow-控制背景的变化
    component: () => import('@/views/other/textPage.vue'), //服务协议 隐私政策
  },
  {
    path: '/help/operate/:id?',
    name: 'helpOperate',
    meta: {
      title: "操作手册",
      key: '',
      bgShow: 6
    }, //bgShow-控制背景的变化
    component: () => import('@/views/other/help/operate.vue'), //操作手册
  },
  {
    path: '/help/problem/:id?',
    name: 'helpProblem',
    meta: {
      title: "常见问题",
      key: '',
      bgShow: 6
    }, //bgShow-控制背景的变化
    component: () => import('@/views/other/help/problem.vue'), //常见问题
  },
  {
    path: '/help/tutorials/:id?',
    name: 'helpTutorials',
    meta: {
      title: "视频教程",
      key: '',
      bgShow: 6
    }, //bgShow-控制背景的变化
    component: () => import('@/views/other/help/tutorials.vue'), //视频教程
  },
  {
    path: '/session',
    name: 'session',
    meta: {
      title: "DUIX-DEMO",
      key: '',
      bgShow: 6
    }, //bgShow-控制背景的变化
    component: () => import('@/views/other/session/index.vue'), //数字人对话 DEMO体验
  },
  {
    path: '/callUp',
    name: 'callUp',
    meta: {
      title: "KOL群英征集令",
      key: '',
      bgShow: 6
    }, //bgShow-控制背景的变化
    component: () => import('@/views/other/callUp.vue'), //KOL群英征集令
  },
  {
    path: '/settleIn',
    name: 'settleIn',
    meta: {
      title: "申请入驻",
      key: '',
      bgShow: 6
    }, //bgShow-控制背景的变化
    component: () => import('@/views/other/settleIn/index.vue'), //KOL群英征集令
  },
  {
    path: '/400',
    name: '400',
    meta: {
      title: "400",
      key: '',
      bgShow: 6
    }, //bgShow-控制背景的变化
    component: () => import('@/views/errorPage/400.vue'), //400
  },
  {
    path: '/403',
    name: '403',
    meta: {
      title: "403",
      key: '',
      bgShow: 6
    }, //bgShow-控制背景的变化
    component: () => import('@/views/errorPage/403.vue'), //403
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: "404",
      key: '',
      bgShow: 6
    }, //bgShow-控制背景的变化
    component: () => import('@/views/errorPage/404.vue'), //404
  },
  {
    path: '/500',
    name: '500',
    meta: {
      title: "500",
      key: '',
      bgShow: 6
    }, //bgShow-控制背景的变化
    component: () => import('@/views/errorPage/500.vue'), //500
  },
  {
    path: '/home',
    component: FrameMain,
    redirect: '/home/index',
    children: [{
        path: '/home/index',
        name: 'home',
        meta: {
          title: "首页",
          key: 'home_index',
          bgShow: 1
        }, //bgShow-控制背景的变化
        component: () => import('@/views/home/index.vue') //首页
      }, {
        path: '/home/demo',
        name: 'demo', //-------------------------------------------------------------------  此路由无用 先不要删除
        meta: {
          title: "首页",
          key: 'home_index',
          bgShow: 1
        }, //bgShow-控制背景的变化
        component: () => import('@/views/home/demo.vue') //首页
      }, {
        path: '/workben/index',
        name: 'workben',
        meta: {
          title: "工作台",
          key: 'workben_index',
          bgShow: 3
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/index.vue') //工作台
      }, {
        path: '/workben/digital',
        name: 'digital_twin',
        meta: {
          title: "定制模板",
          key: 'workben_digital',
          parentKey: 'workben_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/digital_twin/index.vue') //定制模板
      }, {
        path: '/workben/digital_edit/:id',
        name: 'digital_edit',
        meta: {
          title: "编辑模板",
          key: 'digital_edit',
          parentKey: 'workben_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/digital_twin/digital_edit.vue') //编辑模板
      }, {
        path: '/workben/sound',
        name: 'sound_cloning',
        meta: {
          title: "声音克隆",
          key: 'workben_sound',
          parentKey: 'workben_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/sound_cloning/index.vue') //声音克隆
      }, {
        path: '/workben/sound_edit/:id',
        name: 'sound_edit',
        meta: {
          title: "编辑声音克隆",
          key: 'sound_edit',
          parentKey: 'workben_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/sound_cloning/sound_edit.vue') //编辑声音克隆
      }, {
        path: '/workben/painting',
        name: 'ai_painting',
        meta: {
          title: "AI背景",
          key: 'workben_painting',
          parentKey: 'workben_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/ai_painting/index.vue') //AI背景
      }, {
        path: '/workben/painting_details/:id',
        name: 'painting_details',
        meta: {
          title: "背景详情",
          key: 'painting_details',
          parentKey: 'workben_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/painting_details/painting_details.vue') //背景详情
      }, {
        path: '/workben/comics',
        name: 'ai_comics',
        meta: {
          title: "AI换脸",
          key: 'workben_comics',
          parentKey: 'workben_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/ai_comics/index.vue') //AI换脸
      }, {
        path: '/workben/comics_details/:id',
        name: 'comics_details',
        meta: {
          title: "换脸详情",
          key: 'comics_details',
          parentKey: 'workben_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/comics_details/comics_details.vue') //换脸详情
      }, {
        path: '/workben/drama/:id',
        name: 'drama',
        meta: {
          title: "新建台本",
          key: 'workben_drama',
          parentKey: 'workben_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/drama_detail/index.vue') //新建台本
      }, {
        path: '/workben/drama_album/:id',
        name: 'drama_album',
        meta: {
          title: "新建台本专辑",
          key: 'workben_drama_album',
          parentKey: 'workben_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/drama_album/index.vue') //新建台本专辑
      }, {
        path: '/workben/tiben_market',
        name: 'tiben_market',
        meta: {
          title: "台本市场",
          key: 'workben_tiben',
          parentKey: 'workben_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/tiben_market/index.vue') //台本市场
      }, {
        path: '/workben/drama_details/:id',
        name: 'drama_details',
        meta: {
          title: "台本详情",
          key: 'drama_details',
          parentKey: 'workben_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/tiben_market/drama_details.vue') //台本详情
      }, {
        path: '/workben/album_details/:id',
        name: 'album_details',
        meta: {
          title: "专辑详情",
          key: 'album_details',
          parentKey: 'workben_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/workben/tiben_market/album_details.vue') //台本详情
      }, {
        path: '/digital/index',
        name: 'digital',
        meta: {
          title: "数字市场",
          key: 'digital_index',
          bgShow: 3
        }, //bgShow-控制背景的变化
        component: () => import('@/views/digital/index.vue') //数字市场
      }, {
        path: '/digital/combine_card',
        name: 'combine_card',
        meta: {
          title: "数字名片",
          key: 'digital_combine_card',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/digital/components/business_card/combine_card.vue') //数字市场 合成名片
      }, {
        path: '/digital/model_details/:id',
        name: 'model_details',
        meta: {
          title: "模特详情",
          key: 'model_details',
          parentKey: 'digital_index',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/digital/model_details/index.vue') //模特详情
      }, {
        path: '/creation/index',
        name: 'creation',
        meta: {
          title: "作品管理",
          key: 'creation_index',
          bgShow: 4
        }, //bgShow-控制背景的变化
        component: () => import('@/views/creation/index.vue') //作品管理
      }, {
        path: '/creation/videoWorks',
        name: 'videoWorks',
        meta: {
          title: "视频作品",
          key: 'creation_video',
          parentKey: 'creation_index',
          bgShow: 4
        }, //bgShow-控制背景的变化
        component: () => import('@/views/creation/videoWorks/index.vue') //作品管理==视频
      }, {
        path: '/creation/videoDetails/:id',
        name: 'videoDetails',
        meta: {
          title: "视频作品详情",
          key: 'video_details',
          parentKey: 'creation_video',
          bgShow: 4
        }, //bgShow-控制背景的变化
        component: () => import('@/views/creation/videoDetails/index.vue') //作品管理==视频详情
      }, {
        path: '/createvideo/index/:id',
        name: 'createvideo',
        meta: {
          title: "视频创建",
          key: 'create_video',
          parentKey: 'workben_index',
          bgShow: 2
        }, //bgShow-控制背景的变化
        component: () => import('@/views/createvideo/index.vue') //视频/名片作品==创建
      },
      //  {
      //   path: '/createacard/index/:id',
      //   name: 'createcard',
      //   meta: {
      //     title: "数字名片创建",
      //     key: 'create_card',
      //     parentKey: 'workben_index',
      //     bgShow: 2
      //   }, //bgShow-控制背景的变化
      //   component: () => import('@/views/createacard/index.vue') //数字名片==创建
      // },
      {
        path: '/creation/audioWorks',
        name: 'audioWorks',
        meta: {
          title: "音频作品",
          key: 'creation_audio',
          parentKey: 'creation_index',
          bgShow: 4
        }, //bgShow-控制背景的变化
        component: () => import('@/views/creation/audioWorks/index.vue') //作品管理==音频
      }, {
        path: '/creation/audioDetails/:id',
        name: 'audioDetails',
        meta: {
          title: "音频作品详情",
          key: 'audio_details',
          parentKey: 'creation_audio',
          bgShow: 6
        }, //bgShow-控制背景的变化
        component: () => import('@/views/creation/audioDetails/index.vue') //作品管理==音频详情
      }, {
        path: '/createaudio/index/:id',
        name: 'createaudio',
        meta: {
          title: "音频创建",
          key: 'createaudio',
          parentKey: 'workben_index',
          bgShow: 2
        }, //bgShow-控制背景的变化
        component: () => import('@/views/createaudio/index.vue') //音频作品==创建
      }, {
        path: '/creation/drawWorks',
        name: 'drawWorks',
        meta: {
          title: "绘画作品",
          key: 'creation_draw',
          parentKey: 'creation_index',
          bgShow: 4
        }, //bgShow-控制背景的变化
        component: () => import('@/views/creation/drawWorks/index.vue') //作品管理==绘画
      }, {
        path: '/person/index',
        name: 'person',
        meta: {
          title: "我的",
          key: 'person_index',
          bgShow: 5
        }, //bgShow-控制背景的变化
        component: () => import('@/views/person/index.vue') //我的
      }, {
        path: '/person/customer',
        name: 'customer',
        meta: {
          title: "客户管理",
          key: 'person_customer',
          parentKey: 'person_index',
          bgShow: 5
        }, //bgShow-控制背景的变化
        component: () => import('@/views/person/customer_management/index.vue') //客户管理
      }, {
        path: '/person/order',
        name: 'order',
        meta: {
          title: "我的订单",
          key: 'person_order',
          parentKey: 'person_index',
          bgShow: 5
        }, //bgShow-控制背景的变化
        component: () => import('@/views/person/order/index.vue') //我的订单
      }, {
        path: '/person/workConsumption',
        name: 'workConsumption',
        meta: {
          title: "作品消耗",
          key: 'person_workConsumption',
          parentKey: 'person_index',
          bgShow: 5
        }, //bgShow-控制背景的变化
        component: () => import('@/views/person/workConsumption/index.vue') //作品消耗
      }, {
        path: '/person/accountManage',
        name: 'accountManage',
        meta: {
          title: "账号管理",
          key: 'person_accountManage',
          parentKey: 'person_index',
          bgShow: 5
        }, //bgShow-控制背景的变化
        component: () => import('@/views/person/accountManage/index.vue') //账号管理
      }, {
        path: '/person/permission',
        name: 'permission',
        meta: {
          title: "权限管理",
          key: 'person_permission',
          parentKey: 'person_index',
          bgShow: 5
        }, //bgShow-控制背景的变化
        component: () => import('@/views/person/permission_management/index.vue') //账号管理
      }, {
        path: '/person/addEdit',
        name: 'addEdit',
        meta: {
          title: "权限管理",
          key: '',
          parentKey: 'person_index',
          bgShow: 5
        }, //bgShow-控制背景的变化
        component: () => import('@/views/person/permission_management/addEdit.vue') //账号管理
      }, {
        path: '/person/member/:id',
        name: 'member',
        meta: {
          title: "成员管理",
          key: 'person_member',
          parentKey: 'person_index',
          bgShow: 5
        }, //bgShow-控制背景的变化
        component: () => import('@/views/person/member_management/index.vue') //账号管理
      }, {
        path: '/person/message',
        name: 'message',
        meta: {
          title: "消息中心",
          key: 'person_message',
          parentKey: 'person_index',
          bgShow: 5
        }, //bgShow-控制背景的变化
        component: () => import('@/views/person/message_center/index.vue') //账号管理
      }, {
        path: '/person/messageDetails',
        name: 'messageDetails',
        meta: {
          title: "消息中心",
          key: '',
          parentKey: 'person_index',
          bgShow: 5
        }, //bgShow-控制背景的变化
        component: () => import('@/views/person/message_center/details.vue') //账号管理==消息详情
      }
    ]
  }
]



const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 当前时间戳
  var time = parseInt(new Date().getTime() / 1000) + ''
  if (store.state.token) {
    if (store.state.noTokenTime) {
      if (time * 1 > store.noTokenTime * 1) {
        // 已登录,已过期
        delInfo()
        // 跳转登录页面
        next('/login')
      } else {
        // 已登录,未过期
        if (to.path == '/login') {
          var url = store.state.tabsArr.tabList[0].url
          next(url)
        } else {
          toNext(to)
          next()
        }
      }
    } else {
      // 已登录
      if (to.path == '/login') {
        var url = store.state.tabsArr.tabList[0].url
        next(url)
      } else {
        toNext(to)
        next()
      }
    }
  } else {
    if (to.path == '/login') {
      // 判断是登录页面
      next()
    } else if (to.name == 'textPage' || to.name == 'helpOperate' || to.name == 'helpProblem' || to.name == 'helpTutorials') {
      // 判断是服务协议,隐私政策,操作手册,常见问题,视频教程页面
      next()
    } else {
      // 判断是其他需要登录的页面
      delInfo()
      next('/login')
    }
  }
})
// 清除储存
function delInfo() {
  localStorage.clear()
  // 清除token
  store.commit('DEL_TOKEN', '')
  // 清除7天免登录
  store.commit('SET_TIME', '')
  // 清除用户信息
  store.commit('userInfo/DEL_USER_INFO', '')
  // 清除顶部菜单
  store.commit('tabsArr/SET_TABKEY', '')
  store.commit('tabsArr/SET_TABSARR', '')
  // 清除左侧菜单
  store.commit('asideNav/SET_NAVKEY', '')
  store.commit('asideNav/SET_NAVTWOKEY', '')
}
// 正常跳转
function toNext(to) {
  // 修改标题
  if (to.meta.title) {
    document.title = to.meta.title
  }
  store.dispatch('tabsArr/addTabsArr', to)
  // 改变背景色
  store.commit('BG_SHOW', to.meta.bgShow)
}

export default router
