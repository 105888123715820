import Vue from 'vue'
import Vuex from 'vuex'
import userInfo from '@/store/modules/user'
import tabsArr from '@/store/modules/tabs_arr'
import asideNav from '@/store/modules/aside_nav'
import videoCreate from '@/store/modules/video_create'
import voiceInfo from '@/store/modules/voice_info'
import videoData from '@/store/modules/video_data'
Vue.use(Vuex)

export default new Vuex.Store({
  state: { // 储存的数据
    bgShow: localStorage.getItem('bgShow') || 3, //背景  1是首页背景 2是工作台背景 3是无背景
    token: localStorage.getItem('token') || '',
    noTokenTime: localStorage.getItem('noTokenTime') || '', //7天免登录
    // 链接/视频转台本
    book_url: '',
    book_text: '',
    // 创建音频提示
    audioTipShow: localStorage.getItem('audioTipShow') || 1,
  },
  mutations: { // 修改
    // 储存token
    SET_TOKEN(state, token) {
      state.token = token
      localStorage.token = token
    },
    // 清除token
    DEL_TOKEN(state, token) {
      state.token = ''
      localStorage.removeItem('token')
    },
    // 7天免登录
    SET_TIME(state, val) {
      state.noTokenTime = val
      localStorage.noTokenTime = val
    },
    // 背景
    BG_SHOW(state, val) {
      state.bgShow = val
      localStorage.bgShow = val
    },
    // 链接/视频转台本
    SET_BOOK(state, info) {
      console.log(info)
      if (info.type == 1) {
        state.book_text = info.val
      }
      if (info.type == 2) {
        state.book_url = info.val
      }
    },
    // 创建音频提示
    AUDIO_TIP_SHOW(state, val) {
      state.audioTipShow = val
      localStorage.audioTipShow = val
    },
  },
  actions: { // 发起异步请求

  },
  modules: { // 模块拆分
    userInfo,
    tabsArr,
    asideNav,
    videoCreate,
    voiceInfo,
    videoData,
  }
})
