// 公共api
import axios from '../request/request.js'
//
export default {
  // 服务协议
  registerAgreement(data) {
    return axios({
      url: "/member/account/register_agreement",
      method: "get",
      data,
    });
  },
  // 短信发送
  sendSms(data) {
    return axios({
      url: "/member/utils/send_sms",
      method: "post",
      data,
    });
  },
  // 登陆
  postLogin(data) {
    return axios({
      url: "/member/account/login",
      method: "post",
      data,
    });
  },
  // 退出登录
  postLogOut(data) {
    return axios({
      url: "/member/account/logout",
      method: "get",
      data,
    });
  },
  // 注册
  postRegister(data) {
    return axios({
      url: "/member/account/register",
      method: "post",
      data,
    });
  },
  // 登录人权限
  routeList(data) {
    return axios({
      url: "/member/role/self_menus",
      method: "get",
      data,
    });
  },
  // 上传图片
  uploadImg(data) {
    return axios({
      url: "/member/utils/upload_file",
      method: "post",
      data,
      'Headers':{'Content-Type': 'multipart/form-data'}
    });
  },
  // 上传语料
  uploadAudio(data) {
    return axios({
      url: "/member/tts_list/upload_audio",
      method: "post",
      data,
      'Headers':{'Content-Type': 'multipart/form-data'}
    });
  },
  // 上传视频
  uploadVideo(data) {
    return axios({
      url: "/member/model_list/upload_video",
      method: "post",
      data,
      'Headers':{'Content-Type': 'multipart/form-data'}
    });
  },
  // 上传授权视频
  uploadGrantFile(data) {
    return axios({
      url: "/member/model_list/upload_grant_file",
      method: "post",
      data,
      'Headers':{'Content-Type': 'multipart/form-data'}
    });
  },
  // 操作手册 1 常见问题 2 视频教程 3
  helpTheme(data) {
    return axios({
      url: "/member/help/theme/"+data.id,
      method: "get",
    });
  },
  // 文档===详情
  helpArticle(data) {
    return axios({
      url: "/member/help/article/"+data.id,
      method: "get",
    });
  },
}
