const voiceInfo = {
  namespaced: true,
  state: { // 储存的数据
    default_tts: {}, //默认声音
    sample_rate: [], //采样率
    labels: [], //声音分类
    tts_list: [], //声音列表
    ttsType: 1, //ai合成 2上传 3我的
    current: 1, //1编辑文案  2编辑视频
  },
  mutations: { // 修改
    // 默认声音
    SET_DEFAULT_TTS(state, value) {
      state.default_tts = value
    },
    // 采样率
    SET_SAMPLE_RATE(state, value) {
      state.sample_rate = value
    },
    // 声音分类
    SET_LABELS(state, value) {
      state.labels = value
    },
    // 声音弹窗列表
    SET_TTS_LIST(state, value) {
      state.tts_list = value
    },
    SET_CURRENT(state, value) {
      state.current = value
    },
    // 声音弹窗列表
    SET_TTS_TYPE(state, value) {
      state.ttsType = value
    },
  },
  actions: {
    // 初始化state
    initialization({
      state
    }) {
      state.default_tts = {}
      state.sample_rate = []
      state.labels = []
      state.tts_list = []
      state.ttsType = 1
      state.current = 1
    },
  },

};
export default voiceInfo;
