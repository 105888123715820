<!-- @Author: Yu_Bo -->
<template>
	<div class="lower_right_corner">
	  <el-popover popper-class="popover_corner" :offset='-50' placement="left-end" width="170" trigger="hover">
	    <div class="corner_box">
	      <div class="img">
	        <img src="@/assets/images/nav/person_icon_c.png" alt="">
	      </div>
	      <div class="title">添加客服微信</div>
	      <div class="kf_img">
	        <div class="top"></div>
	        <div class="bottom"></div>
	        <div class="left"><span></span></div>
	        <div class="right"><span></span></div>
	        <img class="img_ewm" src="@/assets/images/img/service_img.png" alt="">
	      </div>
	    </div>
	    <img class="kf_icon" slot="reference" src="@/assets/images/img/kf_img.png" alt="">
	  </el-popover>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{

			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {},
	}
</script>

<style lang='scss' scoped>
	/* 右下角客服 */
	.lower_right_corner{
	  position: fixed;
	  bottom: 100px;
	  right: 20px;
	  z-index: 100;
	  width: 54px;
	  height: 54px;
	  .kf_icon{
	    display: block;
	    width: 100%;
	    height: 100%;
	  }
	}
	/*  */
</style>
