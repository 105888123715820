<!-- @Author: Yu_Bo -->
<template>
  <div>
    <div>
      <el-dialog class='account_balance zc_dialog_inv' :visible.sync="dialogVisible" width="720px" :close-on-click-modal='false' :append-to-body="true">
        <div class="inv_title">
          <div class="title">账户余额</div>
          <div class="money">
            <img src="@/assets/images/icon/diamond_icon.png" alt="">
            <span>{{diamond || 0}}</span>
          </div>
        </div>
        <div class="inv_content" v-loading='loading'>
          <div class="surplus">钻石充值：</div>
          <div class="money_list">
            <div class="list" :class="money_index==index?'list_active':''" v-for="(item,index) in moneyList" :key="index" @click="moneyBtn(index)">
              <div class="list_top">
                <span class="time">{{item.diamond_number}}</span>
                <img src="@/assets/images/icon/diamond_icon.png" alt="">
              </div>
              <div class="list_bot">
                <span>￥{{item.earn_number}}</span>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="pay_btn">
            <div class="left">
              <div class="left_tip">
                <span>1.钻石可用于购买平台内模特、场景、视频时长、语音模型、台本；</span>
                <span>2.钻石为虚拟币，充值后的钻石不会过期，但无法提现或者转增他人；</span>
              </div>
            </div>
            <div class="right">
              <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn">立即充值</el-button>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 支付 -->
    <pay-page ref='dialogPay' @renovate='renovateBtn'></pay-page>
  </div>
</template>

<script>
  import PayPage from '@/components/pay_page/pay_page.vue'//支付
  export default {
    components: {PayPage},
    props: {},
    data() {
      return {
        dialogVisible: false,
        loading:false,
        // 剩余数据
        balance:'',
        diamond:'',
        moneyList: [],
        money_index: 1,
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      // 打开弹框
      openDialogBtn(){
        this.dialogVisible=true
        this.money_index=0
        this.rechangeInfo(1)
      },
      // 获取充值数据
      rechangeInfo(index){
        var that = this
        that.loading=true
        var params = {
          type:15
        }
        that.$payApi.getImmediately(params).then(res => {
          that.loading=false
          if (res.code == 1000) {
            that.moneyList=res.result.data
            that.balance=res.result.balance
            that.diamond=res.result.diamond
            if(index==2){
              that.$emit('renovate',that.diamond)
            }
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 选择
      moneyBtn(index){
        this.money_index=index
      },
      // 立即充值
      affirmBtn(){
        var obj = this.moneyList[this.money_index]
        this.$refs.dialogPay.openDialogBtn(obj)
      },
      // 钻石支付关闭刷新
      renovateBtn(){
        this.rechangeInfo(2)
      },
    },
  }
</script>

<style lang='scss' scoped>
  .account_balance {
    ::v-deep .el-dialog {
      border-radius: 20px;
      background: none;
    }

    .inv_title {
      padding-left: 32px;
      height: 96px;
      border-radius: 20px 20px 0 0;
      background: url(~@/assets/images/background/recharge_img.jpg) no-repeat 100% 100%;

      .title {
        padding-top: 20px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }

      .money{
        padding-top: 10px;
        display: flex;
        align-items: center;

        img {
          display: block;
          width: 20px;
          height: 20px;
          padding-top: 2px;
        }

        span {
          padding-left: 7px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }

      }

    }

    .inv_content {
      padding: 20px 20px 20px;
      border-radius: 0 0 20px 20px;
      background: linear-gradient(153deg, #EDF0FF 0%, #CDDEFF 100%);

      .surplus {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;

        span {
          padding-left: 5px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }

      .money_list {
        display: flex;
        flex-wrap: wrap;

        .list {
          box-sizing: border-box;
          cursor: pointer;
          width: 155px;
          height: 120px;
          margin-top: 20px;
          margin-right: 20px;
          background: #FFFFFF;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .list_top {
            color: $bColor3;
            display: flex;
            align-items: center;
            justify-content: center;

            .time {
              font-size: 30px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
            }

            img {
              display: block;
              width: 20px;
              height: 20px;
              margin-top: 10px;
              margin-left: 5px;
            }
          }

          .list_bot {
            padding-top: 10px;
            color: $bColor6;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              margin-top: -2px;
              padding-left: 7px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
            }
          }
        }

        .list:nth-child(4n) {
          margin-right: 0;
        }

        .list_active {
          border: 2px solid $blueBorderColor;

          .list_top {
            color: $blueColor1;
          }

          .list_bot {
            color: $blueColor1;
          }
        }
      }

      /*  */
      .pay_btn {
        margin-top: 20px;
        padding: 20px;
        background: #fff;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;

        .left {

          .left_tip {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;

            span {
              display: block;
              padding-bottom: 5px;
            }

            span:last-child {
              padding-bottom: 0;
            }
          }

        }

        .right {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
        }
      }
    }
  }
</style>
