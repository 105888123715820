const videoData = {
  namespaced: true,
  state: {
    data: {}, //创作页面数据
    sceneList: [], //场景列表
    sceneIndex: 0, //选中场景下标

    duration: 0, //音频总时长
    currentTime: 0, //当前播放时间
    paused: true, //是否暂停
    subtitle: '展示字幕', //当前字幕
    subtitleShow: false, //是否展示字幕
    clickTime: 0, //点击轨道更新时间
    showId: null, //选中素材id

    stepList: [], //上一步  下一步
    disable: false, //禁用  为true时候不监听变化
  },
  mutations: {
    SET_DATA(state, value) {
      state.data = value
    },
    SET_SECNE_LIST(state, value) {
      state.sceneList = value ? JSON.parse(value) : []
    },
    SET_SCENE_INDEX(state, value) {
      state.sceneIndex = value
    },
    // 字幕列表
    SET_SUBTITLE_LIST(state, value) {
      state.sceneList[state.sceneIndex].data.subtitleList = value
    },
    // 添加镜像
    SET_FLIP_SECTIONS(state, value) {
      state.sceneList[state.sceneIndex].flipSections = value ? JSON.parse(value) : []
    },
    // 声音数据
    SET_SECNE_DATA(state, value) {
      state.sceneList[state.sceneIndex].data = value
    },
    // 重新上传音频
    SET_DATA_INFO(state, value) {
      state.sceneList[state.sceneIndex].data.ttsAudio = value.ttsAudio
      state.sceneList[state.sceneIndex].data.name = value.name
    },
    SET_STEP_LIST(state, value) {
      state.stepList = value
    },
    SET_LIST_INFO(state, value) {
      state.sceneList[state.sceneIndex].scenes_conf[value.index].style = value.info
    },
    SET_CURRENT_TIME(state, value) {
      state.currentTime = value
    },
    SET_PAUSED(state, value) {
      state.paused = value
    },
    // 字幕
    SET_SUBTITLE(state, value) {
      state.subtitle = value
    },
    SET_SUBTITLE_SHOW(state, value) {
      state.subtitleShow = value
      state.sceneList[state.sceneIndex].data.subtitleShow = value
    },
    SET_CLICK_TIME(state, value) {
      state.clickTime = value
    },
    SET_SHOW_ID(state, value) {
      state.showId = value
    },
    SET_DURATION(state, value) {
      state.duration = value
    },
    SET_DISABLE_INFO(state, value) {
      state.disable = value
    },
  },
  actions: {
    async modifyProduct({
      dispatch,
      commit,
      state
    }, res) {
      if (res.disable) {
        commit('SET_DISABLE_INFO', true)
      } else {
        commit('SET_DISABLE_INFO', false)
      }
      var arr = JSON.parse(JSON.stringify(state.sceneList))
      if (res.type == 'style') {
        // 修改单个样式
        commit('SET_LIST_INFO', res)
      } else if (res.type == 'conf') {
        arr[state.sceneIndex].scenes_conf = res.info
        commit('SET_SECNE_LIST', JSON.stringify(arr))
      } else if (res.type == 'sounds') {
        // 修改背景音乐
        arr[state.sceneIndex].sounds = res.info
        commit('SET_SECNE_LIST', JSON.stringify(arr))
      } else if (res.type == 'resets') {
        let obj = {
          "color": "",
          "objectFit": "cover",
          "type": "",
          "url": "",
        }
        arr[state.sceneIndex].scenes_conf = []
        arr[state.sceneIndex].background = obj
        commit('SET_SECNE_LIST', JSON.stringify(arr))
      } else if (res.type == 'scen') {
        arr = res.info
        commit('SET_SECNE_LIST', JSON.stringify(arr))
      } else if (res.type == 'flipSections') {
        arr = res.info
        commit('SET_FLIP_SECTIONS', JSON.stringify(arr))
      }
    },

    // 保存步骤  最多存五步
    async stepSave({
      dispatch,
      commit,
      state
    }, res) {
      var list = JSON.parse(JSON.stringify(state.stepList))
      if (list.length < 6) {
        list.push(res)
      } else {
        list.splice(0, 1)
        list.push(res)
      }
      commit('SET_STEP_LIST', list)
    },


    // 初始化state
    initialization({
      state
    }) {
      state.data = {}
      state.sceneList = []
      state.sceneIndex = 0
      state.duration = 0
      state.currentTime = 0
      state.paused = true
      state.subtitle = '展示字幕'
      state.subtitleShow = false
      state.clickTime = 0
      state.showId = null
      state.stepList = []
      state.disable = false
    },
  },
};
export default videoData;
