// 个人中心api
import axios from '../request/request.js'
//
export default {
  // 全局有用数据接口
  getMemberConfig(data) {
    return axios({
      url: "/member/member_config",
      method: "get",
      data,
    });
  },
  // 角色列表
  getRole(data) {
    return axios({
      url: "/member/role",
      method: "get",
      data,
    });
  },
  // 添加角色
  postRole(data) {
    return axios({
      url: "/member/role",
      method: "post",
      data,
    });
  },
  // 修改角色
  putRole(data, id) {
    return axios({
      url: "/member/role/" + id,
      method: "put",
      data,
    });
  },
  // 角色状态改变
  roleStatus(data) {
    return axios({
      url: "/member/role/status",
      method: "post",
      data,
    });
  },
  // 权限树型结构
  getMenu(data) {
    return axios({
      url: "/member/menu",
      method: "get",
      data,
    });
  },
  // 角色已分配权限
  roleGetMenus(data) {
    return axios({
      url: "/member/role/get_menus",
      method: "get",
      data,
    });
  },
  // 删除角色
  delRole(data, id) {
    return axios({
      url: "/member/role/" + id,
      method: "delete",
    });
  },
  // 成员管理
  memberList(data) {
    return axios({
      url: "/member/role/member_list",
      method: "get",
      data,
    });
  },
  // 组织/角色参数
  corpRel(data) {
    return axios({
      url: "/member/corp_rel/params",
      method: "get",
      data,
    });
  },
  // 用户状态
  corpRelated(data) {
    return axios({
      url: "/member/corp_related/status",
      method: "post",
      data,
    });
  },
  // 添加用户
  postCorpRelated(data) {
    return axios({
      url: "/member/corp_related",
      method: "post",
      data,
    });
  },
  // 编辑用户
  putCorpRelated(data, id) {
    return axios({
      url: "/member/corp_related/" + id,
      method: "put",
      data,
    });
  },
  // 删除用户
  delCorpRelated(data, id) {
    return axios({
      url: "/member/corp_related/" + id,
      method: "delete",
      data,
    });
  },
  // 我的订单==充值列表
  orderRechargeList(data) {
    return axios({
      url: "/member/recharge",
      method: "get",
      data,
    });
  },
  // 我的订单==充值列表查询参数
  orderRechargeParams(data) {
    return axios({
      url: "/member/recharge/params",
      method: "get",
      data,
    });
  },
  // 我的订单==消耗列表
  orderConsumeList(data) {
    return axios({
      url: "/member/consume",
      method: "get",
      data,
    });
  },
  // 我的订单==消耗列表查询参数
  orderConsumeParams(data) {
    return axios({
      url: "/member/consume/params",
      method: "get",
      data,
    });
  },
  // 我的订单==消耗详情
  orderConsumeDetails(data) {
    return axios({
      url: "/member/consume/" + data,
      method: "get",
      // data,
    });
  },
  // 账号管理==邀请码列表
  inviteApplyList(data) {
    return axios({
      url: "/member/invite_apply",
      method: "get",
      data,
    });
  },
  // 账号管理==邀请码添加
  inviteApplyCodeAdd(data) {
    return axios({
      url: "/member/invite_code/add",
      method: "post",
      data,
    });
  },
  // 账号管理==邀请码可添加数量
  inviteCodeNum(data) {
    return axios({
      url: "/member/invite_code/code_num",
      method: "get",
      data,
    });
  },
  // 账号管理==邀请码明细
  inviteCodeDetails(data) {
    return axios({
      url: "/member/invite_code",
      method: "get",
      data,
    });
  },
  // 账号管理==邀请码记录删除
  inviteApplyDelete(data) {
    return axios({
      url: "/member/invite_apply/" + data,
      method: "delete",
    });
  },
  // 账号管理==邀请码删除
  inviteCodeDelete(data) {
    return axios({
      url: "/member/invite_code/" + data,
      method: "delete",
    });
  },
  // 账号管理==邀请码启用禁用
  inviteCodeStatus(data) {
    return axios({
      url: "/member/invite_code/status",
      method: "post",
      data,
    });
  },
  // 账号管理==组织列表
  organizationList(data) {
    return axios({
      url: "/member/organization",
      method: "get",
      data,
    });
  },
  // 账号管理==组织添加
  organizationAdd(data) {
    return axios({
      url: "/member/organization",
      method: "post",
      data,
    });
  },
  // 账号管理==组织明细
  organizationDetails(data) {
    return axios({
      url: "/member/organization/" + data,
      method: "get",
    });
  },
  // 账号管理==组织删除
  organizationDelete(data) {
    return axios({
      url: "/member/organization/" + data,
      method: "delete",
    });
  },
  // 账号管理==组织修改
  organizationEdit(data, id) {
    return axios({
      url: "/member/organization/" + id,
      method: "put",
      data,
    });
  },
  // 账号管理==账号列表
  corprelatedList(data) {
    return axios({
      url: "/member/corp_related",
      method: "get",
      data,
    });
  },
  // 账号管理==账号添加
  corprelatedAdd(data) {
    return axios({
      url: "/member/corp_related",
      method: "post",
      data,
    });
  },
  // 账号管理==账号明细
  corprelatedDetails(data) {
    return axios({
      url: "/member/corp_related/" + data,
      method: "get",
    });
  },
  // 账号管理==账号删除
  corprelatedDelete(data) {
    return axios({
      url: "/member/corp_related/" + data,
      method: "delete",
    });
  },
  // 账号管理==账号修改
  corprelatedEdit(data, id) {
    return axios({
      url: "/member/corp_related/" + id,
      method: "put",
      data,
    });
  },
  // 账号管理==账号状态修改
  corprelatedStatusEdit(data) {
    return axios({
      url: "/member/corp_related/status",
      method: "post",
      data,
    });
  },
  // 账号管理==申请代理
  agencyApply(data) {
    return axios({
      url: "/member/corp_related/agency_apply",
      method: "post",
      data,
    });
  },
  // 账号管理==账户基础信息
  accountBasicInfo(data) {
    return axios({
      url: "/member/account/basic_info",
      method: "get",
      data,
    });
  },
  // 账号管理==绑定权益
  bindParentAgency(data) {
    return axios({
      url: "/member/account/bind_parent_agency",
      method: "post",
      data,
    });
  },
  // 账号管理==修改名称
  accountEditName(data) {
    return axios({
      url: "/member/account/edit_name",
      method: "post",
      data,
    });
  },
  // 申请入驻
  postKolApply(data) {
    return axios({
      url: "/member/corp_related/kol_apply",
      method: "post",
      data,
    });
  },
  // 客户管理==筛选条件
  customerParams(data) {
    return axios({
      url: "/member/agency/customer_params",
      method: "get",
      data,
    });
  },
  // 客户管理==我的客户列表
  customerList(data) {
    return axios({
      url: "/member/agency/customer",
      method: "get",
      data,
    });
  },
  // 客户管理==添加
  customerAdd(data) {
    return axios({
      url: "/member/agency/customer_add",
      method: "post",
      data,
    });
  },
  // 客户管理==查看资质
  customerRead(data) {
    return axios({
      url: "/member/agency/customer_read",
      method: "get",
      data,
    });
  },
  // 客户管理==解绑
  customerUnbind(data) {
    return axios({
      url: "/member/agency/customer_unbind",
      method: "post",
      data,
    });
  },
  // 客户管理==套餐数据
  customerTopupSelect(data) {
    return axios({
      url: "/member/agency/customer_topup_select",
      method: "get",
      data,
    });
  },
  // 客户管理==套餐充值
  customerTopup(data) {
    return axios({
      url: "/member/agency/customer_topup",
      method: "post",
      data,
    });
  },
  // 客户管理==修改开关状态
  rechargeStatus(data) {
    return axios({
      url: "/member/agency/status",
      method: "post",
      data,
    });
  },
  // 客户管理==编辑客户
  customerUpdate(data) {
    return axios({
      url: "/member/agency/customer_update",
      method: "post",
      data,
    });
  },
  // 客户管理== 明细 数据统计
  statIncome(data) {
    return axios({
      url: "/member/agency/stat_income",
      method: "get",
      data,
    });
  },
  // 客户管理== 明细 充值记录
  getRecharge(data) {
    return axios({
      url: "/member/agency/customer_order",
      method: "get",
      data,
    });
  },
  // 客户管理== 明细 (钻石消耗 兑换码兑换)
  getConsume(data) {
    return axios({
      url: "/member/agency/customer_consume_order",
      method: "get",
      data,
    });
  },
  // 邀请码 === 列表导出
  inviteExport(data) {
    return axios({
      url: "/member/invite_code/export",
      method: "get",
      data,
      responseType: 'blob'
    });
  },
  // 作品消耗==筛选条件
  customerOtherParams(data) {
    return axios({
      url: "/member/consume_other/params",
      method: "get",
      data,
    });
  },
  // 作品消耗==作品消耗列表
  customerOtherList(data) {
    return axios({
      url: "/member/consume_other/index",
      method: "get",
      data,
    });
  },
  // 消息中心==筛选条件
  messageParams(data) {
    return axios({
      url: "/member/message/params",
      method: "get",
      data,
    });
  },
  // 消息中心==作品消耗列表
  getMessageList(data) {
    return axios({
      url: "/member/message",
      method: "get",
      data,
    });
  },
  // 消息中心==详情
  getMessageDetails(data) {
    return axios({
      url: "/member/message/" + data,
      method: "get",
      // data,
    });
  },


}
