<!-- @Author: Yu_Bo -->
<template>
	<div class='aside_nav'>
    <div class="logo">
      <img src="@/assets/images/img/logo_nav.png" alt="">
    </div>
    <div class="nav_list">
      <div class="list" v-if="$has(item.id)" :class="navKey==item.key?'list_active':''" v-for="(item,index) in navList" :key='index' @click="navToPath(item.key,item.path,item.childer)">
        <img :src="navKey==item.key?item.selectedIconPath:item.iconPath" alt="">
        <span>{{item.title}}</span>
        <div class="list_box" v-show="item.childer && item.childer.length && navKey==item.key && twoShow">
          <div class="box_left"></div>
          <div class="box_right">
            <div class="two_nav" v-if="$has(ite.id)" :class="navTwoKey==ite.key?'two_active':''" v-for="(ite,idx) in item.childer" :key="idx" @click.stop="navToPathTwo(item.parentKey,ite.key,ite.path)">
              <img :src="ite.iconPath" alt="">
              <span>{{ite.title}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="service">
      <img src="@/assets/images/img/service_icon.png" alt="">
    </div>
    <div class="content_background" v-if="twoShow" @click="twoShow=false"></div>
	</div>
</template>

<script>
  import { mapState } from "vuex"
	export default {
		components: {},
		props: {},
		data() {
			return{
        twoShow:false,
			}
		},
		computed: {
		  ...mapState({
		  	navKey: state => state.asideNav.navKey,
		  	navTwoKey: state => state.asideNav.navTwoKey,
		  	navList: state => state.asideNav.navList
		  })
		},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 点击跳转 一级
      navToPath(key,path,arr){
        if(this.navKey==key){
          this.twoShow=!this.twoShow
          return
        }
        this.$store.commit('asideNav/SET_NAVKEY',key)
        this.$store.commit('asideNav/SET_NAVTWOKEY','')
        if(arr && arr.length){
          this.twoShow=true
        }else{
          this.twoShow=false
          this.$router.push(path)
        }
      },
      // 点击跳转 二级
      navToPathTwo(parentKey,key,path){
        this.twoShow=false
        this.$store.commit('asideNav/SET_NAVKEY',parentKey)
        this.$store.commit('asideNav/SET_NAVTWOKEY',key)
        this.$router.push(path)
      },
    },
	}
</script>

<style lang='scss' scoped>
	.aside_nav{
    position: relative;
    width: 100%;
    height: 100%;
    .logo{
      width: 100%;
      padding-top: 30px;
      padding-bottom: 50px;
      display: flex;
      justify-content: center;
      img{
        display: block;
        width: 46px;
        height: 72px;
      }
    }
    .nav_list{
      width: 100%;
      .list{
        cursor: pointer;
        position: relative;
        width: 70px;
        height: 70px;
        margin: 0 auto 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img{
          display: block;
          width: 38px;
          height: 38px;
        }
        span{
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }
        /*  */
        .list_box{
          position: absolute;
          left: 78px;
          z-index: 100;
          display: flex;
          align-items: center;
          .box_left{
            width: 0;
            height: 0;
            border-color: #7A3BFF transparent;
            border-width: 0 0 15px 10px;
            border-style: solid;
          }
          .box_right{
            width: 240px;
            padding: 26px 30px;
            border-radius: 10px;
            background: linear-gradient(to bottom right, #A81BFE 0%, #2546FF 100%);
            .two_nav{
              margin-bottom: 10px;
              width: 100%;
              height: 48px;
              padding: 0 0 0 25px;
              border-radius: 24px;
              display: flex;
              align-items: center;
              img{
                display: block;
                width: 28px;
                height: 28px;
              }
              span{
                padding-left: 12px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
              }
            }
            .two_nav:hover{
              background: rgba(255, 255, 255, 0.2);
            }
            .two_active{
              background: rgba(255, 255, 255, 0.2);
            }
            .two_nav:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
      .list:hover{
        background: rgba(255, 255, 255, 0.3);
        border-radius: 16px;
      }
      .list_active{
        background: rgba(255, 255, 255, 0.3);
        border-radius: 16px;
      }

    }
    .service{
      position: absolute;
      bottom: 46px;
      width: 100%;
      padding: 0 20px;
      img{
        cursor: pointer;
        display: block;
        width: 46px;
        height: 46px;
      }
    }
	}
  .content_background{
    position: fixed;
    top: 0;
    left: 86px;
    z-index: 3;
    width: calc(100% - 86px);
    height: 100%;
    background: rgba(0, 0, 0, 0);
  }
</style>
