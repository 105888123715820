const videoCreate = {
  namespaced: true,
  state: { // 储存的数据
    leftNavInfo: {}, // 创作左侧
    rightNavInfo: {}, // 创作右侧
    sceneShow: true, // 场景得展开(true)和收起(false)
    bottomShow: true, // 底部得展开(true)和收起(false)
    scaleRatio: '', // 缩放大小
    sizeRatio: 1, // 宽高比2=9:16 1=16:9
    resolutionRatio: '1920*1080', // 分辨率
    bit_rate: '10M', // 码率
    fps: '25fps', // 帧率
    template_from: 1, //1视频 2名片
    isFull: false, //是否全屏
  },
  mutations: { // 修改
    // 创作左侧
    SET_LEFT_NAV_INFO(state, value) {
      state.leftNavInfo = value
    },
    // 创作右侧
    SET_RIGHT_NAV_INFO(state, value) {
      state.rightNavInfo = value
    },
    // 场景得展开(true)和收起(false)
    SET_SCENE_SHOW(state, value) {
      state.sceneShow = value
    },
    // 底部得展开(true)和收起(false)
    SET_BOTTOM_SHOW(state, value) {
      state.bottomShow = value
    },
    // 缩放大小
    SET_SCALE_RATIO(state, value) {
      if (value >= 1) {
        state.scaleRatio = 1
      } else if (value <= 0.05) {
        state.scaleRatio = 0.05
      } else {
        state.scaleRatio = value
      }
    },
    // 宽高比2=9:16 1=16:9
    SET_SIZE_RATIO(state, value) {
      state.sizeRatio = value
    },
    // 分辨率
    SET_RESOLUTION_RATIO(state, value) {
      state.resolutionRatio = value
    },
    // 码率
    SET_BIT_RATE(state, value) {
      state.bit_rate = value
    },
    // 帧率
    SET_FPS_RATE(state, value) {
      state.fps = value
    },
    // 类别
    SET_TEMPLATE_FROM(state, value) {
      state.template_from = value
    },
    // 开启关闭全屏
    SET_IS_FULL(state, value) {
      state.isFull = value
    },
  },
  actions: { // 发起异步请求
    // 初始化state
    initialization({
      state
    }) {
      state.leftNavInfo = {}
      state.rightNavInfo = {}
      state.sceneShow = true
      state.bottomShow = true
      state.scaleRatio = ''
      state.sizeRatio = 1
      state.resolutionRatio = '1920*1080'
      state.bit_rate = '10M'
      state.fps = '25fps'
      state.template_from = 1
      state.isFull = false
    },
  },

};
export default videoCreate;
