import './theme/index.css'
import Vue from 'vue'
import 'swiper/css/swiper.css'
import ElementUI from 'element-ui'
Vue.use(ElementUI)
// 全局引入图片验证码组件
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);
// 剪切板
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)
import VueDragResize from 'vue-drag-resize'
Vue.component('vue-drag-resize', VueDragResize)
import App from './App.vue'
import router from './router'
import store from './store'
// 判断权限
const has = (val) => {
    let list = store.state.asideNav.roleList
    var obj = list.find(item => item == val)
    if (obj) {
        return true
    } else {
        return false
    }
}
Vue.prototype.$has = has
// 接口api
import publicApi from './axiosApi/public.js'
Vue.prototype.$publicApi = publicApi //公共接口
import payApi from './axiosApi/pay.js'
Vue.prototype.$payApi = payApi //充值模块接口
import personApi from './axiosApi/person.js'
Vue.prototype.$personApi = personApi //我的模块接口
import creationApi from './axiosApi/creation.js'
Vue.prototype.$creationApi = creationApi //作品管理接口
import workbenApi from './axiosApi/workben.js'
Vue.prototype.$workbenApi = workbenApi //工作台接口
import digitalApi from './axiosApi/digital.js'
Vue.prototype.$digitalApi = digitalApi //数字市场接口
import audioApi from './axiosApi/audio.js'
Vue.prototype.$audioApi = audioApi //创建音频
// 过滤器
import * as _filter from "@/filters/index"
Object.keys(_filter).forEach(item => {
    Vue.filter(item, _filter[item])
})
// 系统消息提示
import {
    succMsg,
    warMsg,
    textMsg,
    errMsg,
} from '@/utils/tip.js'
Vue.prototype.$succMsg = succMsg
Vue.prototype.$warMsg = warMsg
Vue.prototype.$textMsg = textMsg
Vue.prototype.$errMsg = errMsg
// 验证
import testVerify from '@/utils/index.js'
Vue.prototype.$testVerify = testVerify
// 时间转换
import utils from '@/utils/times.js'
Vue.prototype.$utils = utils
Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
